.region-listing {
  height: 100%;
  display: grid;
  &.has-content {
    grid-template-rows: auto 1fr;
    grid-template-areas: 'header' 'region-lists';
  }

  .content-fade-in {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-top: $space-sm;
  }

  .kepler-header {
    grid-area: header;
    margin-right: $space-md;
  }

  .region-listing-center-style {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .region-listing-spinner {
      height: 80px;
      width: 80px;
    }
  }

  .region-lists {
    grid-area: 'region-lists';
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: $space-sm 0;
    overflow-y: auto;

    .region-type-section {
      border-radius: $space-sm;
      border: 1px solid lightgray;
      margin: $space-sm 0;
      overflow: hidden;
      transition: box-shadow 0.2s ease-in-out;
      display: flex;
      flex-direction: column;

      @keyframes activity-collapse {
        from {
          max-height: 0;
          overflow: hidden;
        }
        to {
          max-height: 800px;
          overflow: hidden;
        }
      }

      animation: $ui-transition activity-collapse;

      .region-type-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $space-md;
        cursor: pointer;
        text-transform: capitalize;
        font-weight: 700;
        font-size: 17.5px;

        .section-header {
          display: flex;
          align-items: center;
          .count-badge {
            margin-left: $space-sm;
          }
        }

        .caret-icon {
          padding: $space-xs;
          border-radius: $border-radius-md;
          svg * {
            stroke: $gray-dark;
          }
        }
        &:hover {
          .caret-icon {
            background-color: $yembo-blue-light;
            svg * {
              stroke: $yembo-blue;
            }
          }
        }
      }
      .region-type-content {
        margin: 0 0 $space-md $space-md;
        min-height: 0;

        .scan-card {
          .video-preview-image {
            img {
              background-color: #191b1e; /* stylelint-disable-line declaration-property-value-allowed-list */
            }
          }
        }
      }

      &.open {
        flex: 1;

        .caret-icon {
          svg * {
            stroke: $yembo-blue;
          }
        }
      }
      &:not(.open):hover {
        box-shadow: $z-depth-sm;
      }
    }
  }

  .region-cards-wrapper {
    max-height: 100%;
    overflow-y: auto;
    scroll-behavior: smooth;
    margin-right: $space-sm;

    .flex-container {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;

      .media-listing-card {
        margin: $space-sm $space-sm;
        flex: 0 0 calc(100% / 5 - $space-md);

        .region-details {
          display: grid;
          grid-template-columns: auto auto;
          column-gap: $space-sm;
          row-gap: $space-xs;
          margin: $space-lg;
        }

        @media (max-width: 1679px) {
          flex-basis: calc(25% - $space-md);
        }

        @media (max-width: 1350px) {
          flex-basis: calc(33.33% - $space-md);
        }

        @media (max-width: 927px) {
          flex-basis: calc(50% - $space-md);
        }

        @media (max-width: 750px) {
          flex-basis: calc(100% - $space-md);
        }

        &.processing,
        &.failed {
          cursor: not-allowed;
        }
      }
    }
  }

  .empty-state-wrapper {
    .empty-state-text {
      margin-top: $space-sm;
    }
  }

  .region-tab {
    margin-left: $space-sm;
  }

  .region-listing-footer {
    grid-area: footer;
    margin-right: $space-md;
    z-index: 5;
    .yb-button {
      margin: $space-lg 0 $space-xs 0;
    }
  }

  .yb-accent-color-icon.placeholder-icon,
  .yb-scalable-icon-container.placeholder-icon {
    padding-top: $space-sm;
    svg {
      width: 56px;
      height: 56px;
    }
  }

  .awaiting-placeholder {
    height: 56px;
  }
}

.delete-region-modal {
  .action-description-text {
    margin-top: $space-sm;
  }
}
