.tasks-grid-wrapper {
  $grid-template-columns-sm: 50% 27% auto 64px;
  $grid-template-columns-md: 40% 27% auto 64px;
  $grid-template-columns-lg: 35% 27% auto 64px;

  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-height: 0px;
  overflow-x: auto scroll;
  margin-left: $space-md;
  position: relative;

  .grid-head-row {
    display: grid;
    grid-template-columns: $grid-template-columns-md;
    background: $white;
    color: $header-color;
    font-weight: 600;
    height: 48px;
    margin-right: $space-md;
    min-width: 653px;
    border: 1px solid transparent;
    box-sizing: border-box;
  }

  .tasks-grid-content {
    height: 100%;
    min-height: 0px;
    overflow: hidden scroll;
    min-width: fit-content;
    position: relative;

    .annotation-tasks-list {
      overflow-y: scroll !important;
    }

    .grid-row {
      display: grid;
      grid-template-columns: $grid-template-columns-md;
      background: $white;
      box-sizing: border-box;
      border: 1px solid $gray-border;
      border-radius: $border-radius-md;
      align-items: center;
      min-height: 48px;
      min-width: 653px;
      margin: $space-xs $space-sm $space-sm 0;
      padding: $space-xs * 3 0;
      cursor: pointer;
      transition: $ui-transition box-shadow;

      .task-assignee-dropdown {
        border: 1px solid transparent;
        border-radius: $border-radius-sm;
        box-sizing: border-box;
        padding: $space-xs $space-sm;
        transition: $ui-transition border;

        .dropdown-text {
          padding-left: 0;
        }

        .yb-icon {
          visibility: hidden;
          svg * {
            stroke: $gray-dark;
          }
        }
        &.open {
          border: 1px solid $yembo-blue;
          .yb-icon {
            visibility: visible;
          }
        }
      }

      .task-description-textarea {
        border: 1px solid transparent;
        outline: none;
        border-radius: $border-radius-sm;
        padding: calc($space-xs / 2) $space-sm;
        font-size: $body-text;
        box-sizing: content-box;
        line-height: 142.8%;
        transition: $ui-transition border-color;
        width: 80%;
        resize: none;

        &:focus {
          border-color: $yembo-blue !important;
        }
      }

      &:hover:not(:focus) {
        box-shadow: $z-depth-sm;

        .task-description-textarea {
          border-color: #d5e0e8;
        }

        .task-assignee-dropdown:not(.open) {
          border: 1px solid $gray-border;
          .yb-icon {
            visibility: visible;
          }
          &:hover {
            border: 1px solid $gray-dark;
            .yb-icon {
              svg * {
                stroke: $gray-dark;
              }
            }
          }
        }

        .grid-cell {
          .delete-button {
            visibility: visible;
          }
        }
      }
    }
  }

  .grid-cell {
    padding: 0 $space-md;
    display: flex;
    align-items: center;
    height: 100%;
    text-overflow: ellipsis;

    &.assignee-cell {
      padding-left: $space-sm;
    }

    .delete-button {
      visibility: hidden;
    }
  }

  @media screen and (max-width: 1366px) {
    .grid-head-row {
      grid-template-columns: $grid-template-columns-sm;
    }

    .tasks-grid-content {
      .grid-row {
        grid-template-columns: $grid-template-columns-sm;
      }
    }
  }

  @media screen and (min-width: 1920px) {
    .grid-head-row {
      grid-template-columns: $grid-template-columns-lg;
    }

    .tasks-grid-content {
      .grid-row {
        grid-template-columns: $grid-template-columns-lg;
      }
    }
  }
}

.delete-annotation-task-modal {
  .modal-content-container {
    max-width: 502px;
    box-sizing: border-box;

    .modal-header {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.task-assignee-menu {
  top: 2px;
  padding: $space-sm 0;

  .menu-item {
    white-space: nowrap !important;
  }
}
