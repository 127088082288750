@import './ImageItem';
@import './ContextMenu';
@import './SlidingDrawer';
@import './AddImage';
@import './VideoThumbnail';

.review-carousel {
  border-radius: 16px;
  border: 1px solid $gray-border;
  margin-bottom: $space-sm;
  position: relative;
  overflow: hidden;
  padding-bottom: $space-xs;

  .scroll-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow-x: scroll;
  }

  & > :first-child {
    margin-left: $space-md;
  }

  .image-item-wrapper {
    position: relative;

    &.highlight {
      outline: 1px $yembo-blue;
    }
  }

  &.spinner-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 396px;
    flex-direction: column;
    .kepler-spinner {
      height: 80px;
      width: 80px;
    }
  }

  .carousel-map-container {
    display: flex;
    flex: 1;
    min-width: 297px;
    height: 396px;
    margin: $space-md $space-md $space-sm 0;

    .map-container__map {
      /* stylelint-disable-line selector-class-pattern */
      border-radius: $border-radius-sm;
    }
  }

  .plug-container {
    min-width: 320px;
    height: 1px; // need for safari
    background: transparent;
    transition: $ui-transition min-width;
    &.hidden {
      min-width: 0;
    }
  }
}
