@import './Dashboard';

.reporting {
  display: flex;
  width: 100%;
  .yb-top-navbar {
    color: $white;
  }
  .yb-mover-screen {
    .content-wrap {
      max-height: 100vh;
      .content-container {
        padding: $space-md 0;
        .kepler-header {
          margin: 0 $space-md;
        }
      }
    }
  }
}
