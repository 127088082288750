.sessions-wrapper {
  display: flex;
  flex-direction: column;

  .sessions-list {
    margin-top: $space-md;

    .session {
      display: flex;
      height: 24px;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $space-sm;
      cursor: pointer;

      div {
        height: 24px;
        .delete {
          display: none;

          svg:hover {
            path {
              stroke: $danger-base;
            }
          }
        }
      }

      &:hover {
        .delete {
          display: block;
        }
      }
    }
  }

  .revoke-button {
    padding: 0;

    &:hover {
      background: transparent;
    }

    .button-content {
      justify-content: flex-start;
    }
  }
}
