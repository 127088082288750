#login-new,
.login-new {
  .section-header {
    margin-bottom: $space-md;

    &.has-error {
      margin-bottom: calc($space-md * 3) !important;
    }

    position: relative;

    h1 {
      margin-bottom: $space-xs !important;
    }

    p {
      margin: 0 !important;
    }

    .error-message {
      padding: $space-sm;
      border-radius: $space-sm;
      background-color: $danger-xlight;
      margin-top: calc($space-xs * 3);
      font-size: $space-md - $space-xxs;
    }
  }
}
