@import './PropertyDetails/';
@import './ClaimSubtype/';
@import './EmployeeSelector/';
@import './ClaimAffectedAreas/';
@import './SendInvite/';
@import './UnderwritingType/';

.create-project-modal {
  .modal-content-container {
    max-width: 496px;
    width: 496px;
    box-sizing: border-box;

    .modal-header-wrapper {
      margin-right: $space-xl;
    }
  }

  .modal-loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;

    .modal-loading {
      height: 75px;
      width: 75px;
    }
  }

  .modal-footer-wrapper {
    margin-top: $space-lg !important;
  }

  &.verify-address-screen {
    .modal-content-container {
      max-width: 456px;
      width: 456px;
    }
  }

  &.send-invite-screen {
    .modal-content-container {
      max-width: 536px;
      width: fit-content;
    }

    .modal-footer-wrapper {
      margin-top: $space-sm !important;
    }
  }
}
