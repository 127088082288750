.yb-modal.property-edit-modal {
  .modal-content-container {
    width: 408px;
  }

  .property-edit-modal-content {
    padding: $space-sm $space-md;
    margin-bottom: $space-lg;
    margin-left: $space-md;
    width: 376px;
    box-sizing: border-box;

    .address-row {
      display: grid;
      grid-template-columns: auto 40px 80px;
      column-gap: $space-md + $space-sm;

      .yb-text-field {
        min-width: 40px;
        .textfield-label {
          white-space: nowrap;
        }
      }
    }

    .yb-dropdown {
      margin-bottom: $space-sm;
    }
  }

  .modal-footer-wrapper {
    margin-top: 0;
  }
}
