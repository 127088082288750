.messages-placeholder {
  position: absolute;
  width: 100%;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  z-index: $popover-z-index;
  &.kepler-backoffice-notification {
    bottom: 80px;
  }
  &.kepler-editor-notification {
    bottom: 40px;
  }

  > div {
    max-width: 66%;
  }

  .kepler-notification {
    width: auto;
    pointer-events: all;
    margin-top: $space-xxs;

    @include content-fade-in;
  }
}
