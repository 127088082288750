.requirements-wrapper {
  .requirement-title {
    padding-top: $space-sm;
    padding-bottom: $space-sm;
    text-align: left;
  }

  .requirement-list {
    .requirement-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 2px;
      padding-top: 2px;

      .yb-icon {
        padding-right: $space-sm;
        height: 14px;
        width: 14px;

        svg {
          path {
            display: none;
          }
        }

        path {
          stroke: $dm-muted-text-color;

          &:nth-child(2) {
            display: none;
          }
        }

        &.matched {
          path {
            stroke: $success-base;
            display: block;

            &:nth-child(2) {
              display: block;
            }
          }
        }
      }
    }
  }
}
