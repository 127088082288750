.bookmark-panel {
  padding: $space-md 0;
  width: 360px;
  right: 75px;
  flex-direction: column;

  > h3 {
    padding: 0 $space-md;
    margin-bottom: $space-md;
    color: $white;
  }

  .add-bookmark-container {
    padding: 0 $space-md;
    .preview-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: $space-md;
      #view-preview-photo {
        max-height: 145px;
      }
    }
    .bk-btn-container {
      display: flex;
      flex-direction: column;
      margin: 0px $space-xl;
      .textfield-label {
        font-size: $tiny-text;
        color: $white;
      }
    }
    input {
      background-color: transparent;
      color: $white !important;
    }
    .yb-text-field {
      &:focus-within {
        .textfield-label {
          color: $yembo-blue-dark-mode !important;
        }
        .textfield-input-wrapper {
          border-color: $yembo-blue-dark-mode !important;
        }
      }
      &:focus {
        .textfield-input {
          color: $white;
        }
      }
    }
    .yb-primary-button {
      margin-bottom: $space-sm !important;
      margin-top: $space-md;
    }
    .yb-tertiary-button {
      color: $yembo-blue-dark-mode !important;
      &:hover {
        background-color: $dm-background-extra-light;
      }
    }
  }
  .bookmark-container {
    display: flex;
    flex-direction: column;

    .bookmark-item {
      display: flex;
      flex-direction: row;
      cursor: pointer;
      padding: 0 $space-md;
      margin: $space-xs 0;
      transition: $ui-transition all;
      &:hover {
        background-color: $dm-background-extra-light;
      }
      &:active {
        background-color: $dm-background-mid;
      }
      &.add-btn {
        svg * {
          stroke: $yembo-blue-dark-mode;
        }
        .add-bk-txt {
          position: relative;
          top: 2px;
          color: $yembo-blue-dark-mode;
        }
      }
      &.saved-item {
        .saved-bk-txt {
          position: relative;
          top: 2px;
        }
      }
      .bk-icon {
        margin-right: $space-sm;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    display: initial;
  }
  20% {
    opacity: 0;
    display: initial;
  }
  40% {
    opacity: 0.3;
    display: initial;
  }
  60% {
    opacity: 0.5;
    display: initial;
  }
  80% {
    opacity: 0.9;
    display: initial;
  }
  100% {
    opacity: 1;
    display: initial;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0.9;
  }

  40% {
    opacity: 0.5;
  }

  60% {
    opacity: 0.3;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    display: none;
  }
}
