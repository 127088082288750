.change-password {
  flex: 1;
  max-width: 50%;

  .password-inputs {
    .password-wrapper:nth-child(odd) .textfield-wrap {
      width: 100%;
      padding-right: $space-lg;
    }

    .yb-text-field {
      width: 100%;
      padding-right: $space-lg;
      &:last-child {
        padding-bottom: 0;
      }
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;

      .password-wrapper:nth-child(odd) .textfield-wrap {
        padding-right: 0;
      }
    }

    .password-wrapper {
      min-width: 100%;
    }

    .yb-icon {
      cursor: pointer;

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }

  .change-password-btn {
    height: $space-xl;
    padding: 0;
  }
}
