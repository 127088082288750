@import 'src/react/_components/Menu';

.kepler-context-menu {
  position: fixed;
  height: 100px;
  z-index: $floating-window-z-index;

  .large-item {
    width: 272px;
  }

  .small-item {
    width: 195px;
  }

  .search-bar-container {
    padding: $space-xs $space-md;
    background: $white;

    .yb-search-bar {
      position: initial;

      .yb-icon {
        svg * {
          stroke: $gray-dark;
        }
      }
      .search-bar-input-wrap {
        color: $body-color;
        border: solid 1px $gray-border;
        border-radius: calc(#{$border-radius-lg} + #{$border-radius-md});
      }
      .search-bar-input {
        color: $body-color;
        width: 173px;
      }
      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $muted-text-color;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $muted-text-color;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $muted-text-color;
      }
    }
  }

  .context-menu {
    background-color: $white;
    border-radius: $border-radius-md;

    .item {
      color: $body-color;
      border-radius: 0;
      padding: $space-sm $space-md;

      .sub-menu-item {
        color: $body-color;

        .yb-icon {
          svg * {
            stroke: $gray-dark;
          }
        }
      }

      &.selected {
        background-color: $menu-item-hover;
      }
    }
  }

  .context-menu.main {
    padding: $space-sm 0;
    border-radius: $border-radius-md;
    box-shadow: $z-depth-md;

    .sub-menu {
      padding: $space-sm 0;
      box-shadow: $z-depth-md;
    }
  }
}
