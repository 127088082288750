.label-popover {
  min-width: 306px;
  box-sizing: border-box;

  .popover-header {
    padding-bottom: $space-sm;
  }

  .popover-content {
    padding: 0 $space-sm;
    display: flex;
    flex-direction: column;
    gap: $space-sm;

    label {
      font-weight: 500;
    }

    .content-label {
      margin-top: $space-xs;
      display: flex;
      flex-direction: column;
      gap: $space-xs;

      &:focus-within {
        color: $yembo-blue;
      }

      .content-textarea {
        margin: 0;
        display: block;
        resize: none;
      }

      .character-count {
        align-self: flex-end;
        color: $muted-text-color;
      }
    }

    .save-button {
      align-self: flex-end;
      margin: $space-lg 0 $space-sm 0;
      padding: 0 $space-xl + $space-sm;
    }
  }
}
