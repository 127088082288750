.assignee-dropdown {
  max-width: 218px;
  margin-left: $space-sm;
}

.yb-dropdown .assignee-dropdown-menu {
  max-width: 218px;
  left: $space-md + $space-xs;
  top: $space-sm;
}
