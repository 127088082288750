@import './components/EditPropertyModal';
@import './components/ProjectPopoverMenu/';
@import './components/HazardsList/';
@import './components/InspectionsList';
@import './components/PropertyItem';
@import './components/CreateInspectionModal/';

.project-grid-cell,
.grid-head-cell {
  &.center-aligned {
    display: flex;
    text-align: center;
    justify-content: center;
    margin: auto;
  }
  &.right-aligned {
    text-align: right;
    justify-content: flex-end;
    margin-left: auto;
  }
}

.no-media {
  position: relative;
  top: 4vh;
  text-align: center;
  width: 100%;
  margin-top: $space-xxl;
  color: $muted-text-color;
  font-weight: 400;
  font-size: 14px;
  line-height: 142.8%;
}

.project-overview {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-x: auto;

  .project-panel {
    display: flex;
    flex-direction: column;
    flex: 2;
    background-color: $white;
    border-radius: 16px;
    box-shadow: 10px 4px 20px rgb(0 0 0 / 10%);
    padding: $space-md;
    .project-header {
      font-style: normal;
      font-weight: 700;
      font-size: 17.5px;
      line-height: 24px;
    }
    .action-button-row {
      display: flex;
      flex-direction: row;
      margin: $space-md 0;
      .action-btn {
        flex: 1;
        margin-right: $space-sm;
        &:last-child {
          margin-right: 0;
        }
      }
      .export-btn {
        .yb-blue-spinner {
          margin-right: $space-xs;
        }
      }
    }
    .project-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: scroll;
    }
  }
  .media-panel {
    display: flex;
    flex-direction: column;
    margin-left: $space-sm;
    padding: $space-md;
    flex: 1;
    background-color: $white;
    border-radius: 16px;
    box-shadow: 10px 4px 20px rgb(0 0 0 / 10%);
    min-width: 375px;

    .media-panel-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: $space-md;

      .tooltip-danger {
        p {
          width: 220px;
          text-align: right;
        }
      }

      .verified-chips,
      .danger-chip {
        padding-left: 0;
        overflow: hidden;
      }
      .yb-chip {
        padding: 0 $space-md 0 0;
        &.green {
          border-color: $success-base;
          color: $success-base;
        }
      }
      .verified-chips-inner,
      .danger-chip-inner {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 11.2px;
        line-height: 142.8%;

        @include content-fade-in;

        .icon-checkmark,
        .icon-warning {
          padding: 0 $space-xxs;
          margin: 0 $space-sm 0 0;
          color: $white;
        }
        .icon-checkmark {
          background-color: $success-base;
        }

        .icon-warning {
          background-color: $danger-base;
          svg {
            circle {
              display: none;
            }
          }
        }
      }
    }

    .project-map-container {
      width: 100%;
      flex: 280px 0;
      border: 1px solid $gray-border;
      border-radius: $space-md;
      .wrapper {
        width: 100%;
        height: calc(100% - 20px);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        > p {
          color: $muted-text-color;
        }
        .kepler-spinner {
          height: 80px;
          width: 80px;
        }
      }
    }

    .project-media-section {
      box-sizing: border-box;
      margin: $space-md 0 0 0;
      flex: 1 1;
    }
  }
}

.collapsible-media-item {
  display: flex;
  flex-direction: column;
  padding-left: $space-sm;

  &.open {
    .collapsible-header {
      .caret-icon {
        svg * {
          stroke: $yembo-blue;
        }
      }
    }
  }

  .collapsible-header {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    border-radius: $border-radius-sm;
    transition: $ui-transition background-color;
    padding: $space-xxs $space-sm;
    margin: $space-xs (-$space-xs);
    font-weight: 600;

    .collapsible-title {
      flex: 1;
    }
    .caret-icon {
      margin: $space-xs $space-sm;
      svg * {
        stroke: $gray-dark;
      }
    }
    &:hover {
      background-color: #f0f7ff; /* stylelint-disable-line declaration-property-value-allowed-list */
      cursor: pointer;

      .caret-icon {
        svg * {
          stroke: $yembo-blue;
        }
      }
    }
  }

  .collapsible-content {
    margin: $space-sm 0 $space-sm $space-sm;

    @keyframes activity-collapse {
      from {
        max-height: 0;
        overflow: hidden;
      }
      to {
        max-height: 800px;
        overflow: hidden;
      }
    }

    animation: $ui-transition activity-collapse;
  }

  &:last-child {
    .collapsible-content {
      margin-bottom: 0;
    }
  }
}

.scrollable-section {
  overflow-y: auto;
  margin-right: $space-xs - $space-md;
  padding-right: $space-sm;
}

.media-item {
  display: flex;
  flex-direction: row;
  margin-bottom: $space-sm;
  .legend-text {
    position: relative;
    bottom: 1.5px;
  }
  .legend-sq {
    height: 16px;
    width: 16px;
    border-radius: 2px;
    margin-right: $space-sm;
    box-shadow: inset 1px 1px 4px rgba(45, 49, 54, 0.28);

    &.scans {
      background-color: #ccebc5; /* stylelint-disable-line declaration-property-value-allowed-list */
    }
    &.videos {
      background-color: #b3cde3; /* stylelint-disable-line declaration-property-value-allowed-list */
    }
    &.images {
      background-color: #fbb4ae; /* stylelint-disable-line declaration-property-value-allowed-list */
    }
  }
}
