.sms-chat-container {
  position: fixed;
  bottom: 0px;
  right: $space-xl;
  width: 328px;
  height: 40px;
  border-radius: $space-md;
  box-shadow: 0px 0px 20px 4px rgba(4, 38, 75, 0.23), 0px 0px 6px rgba(4, 38, 75, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.04);
  /* stylelint-disable-next-line value-list-comma-newline-after */
  transition: height 0.3s ease-in-out, width 0.3s ease-in-out, right 0.3s ease-in-out, bottom 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;

  &.open {
    width: 432px;
    height: 584px;
    right: $space-sm;
    bottom: $space-sm;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);

    .sms-chat-header {
      background-color: $yembo-blue;

      .sms-chat-header-text {
        max-width: 300px;
      }

      .popup-caret {
        transform: rotate(180deg);
        transition: transform 0.3s ease-in-out;
      }
    }
  }

  .sms-chat-header {
    background-color: $yembo-navy;
    transition: background-color 0.3s ease-in-out;
    color: $white-85;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: $space-sm $space-md;
    box-sizing: border-box;
    border-radius: $space-md $space-md 0px 0px;
    cursor: pointer;

    .sms-chat-header-content {
      display: flex;
      align-items: center;
    }

    .sms-chat-header-text {
      margin-left: $space-sm;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 204px;
    }

    .unread-notification {
      margin-left: $space-xs;
      background-color: $yembo-blue;
      border-radius: 100px;
      padding: 0 $space-sm;
    }
  }

  .sms-chat-body {
    height: 544px;
    background-color: $white;
    border-radius: 0px 0px $space-md $space-md;
    display: flex;
    flex-direction: column;
    align-items: center;

    .sms-chat-body-content {
      width: 400px;
      height: 348px;
      margin-top: $space-md;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
    }

    .sms-chat-textbox {
      padding: $space-md;
      padding-bottom: calc($space-xl + $space-xs);
      width: 400px;
    }
  }
}
