.employee-details {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid $gray-border;
  border-radius: $border-radius-lg;
  box-sizing: border-box;
  min-width: 306px;
  padding: $space-md 0 $space-md $space-md;
  margin: $space-md $space-md 0 $space-xs;
  opacity: 0;
  animation: 0.7s ease-in 0.2s forwards fadeIn;

  .close-panel-button {
    position: absolute;
    top: 6px;
    right: 6px;
  }

  .employee-details-header {
    margin: 0 $space-md $space-xs 0;

    > p {
      text-transform: capitalize;
    }
  }

  .employee-section-header {
    margin-top: $space-md;

    &.checkbox-header {
      margin-bottom: $space-md;
    }
  }

  .yb-dropdown,
  .yb-text-field-phone {
    margin-bottom: $space-sm;
  }

  .button-container {
    display: flex;
    justify-content: center;

    .yb-primary-button {
      width: 160px;
      margin: $space-md 0;
    }
  }

  .scrollable-div {
    padding: 0 $space-xs * 3 0 $space-sm;
    margin: $space-sm $space-sm $space-sm 0;
    overflow: hidden scroll;
  }
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}
