@import '../ReviewCarousel';
@import '../ReviewActionBar';
@import '../ReviewToggler';
@import '../ProcessingScreen';

.spinner-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .kepler-spinner {
    height: 80px;
    width: 80px;
  }
}
.region-review {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: -$space-md;

  .region-review-contents {
    overflow-y: scroll;
    scroll-behavior: smooth;
    margin-right: $space-xs;
    padding-right: $space-sm;
  }

  .review-action-bar {
    margin-right: $space-md;
  }
}
