@mixin ghost-styles($border: 1px solid $yembo-blue, $color: $yembo-blue, $background: $white, $font-weight: 400) {
  background: $background;
  border: $border;
  color: $color;
  font-weight: $font-weight;
}

.visual-select-button {
  width: 152px;
  height: 144px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius-lg;
  padding: $button-padding;
  box-sizing: border-box;
  cursor: pointer;

  @include ghost-styles();

  &:hover,
  &.active {
    @include ghost-styles(
      $border: 1px solid $yembo-blue-dark,
      $background: $yembo-blue-light,
      $color: $yembo-blue-dark
    );
    .yb-icon {
      svg * {
        stroke: $yembo-blue-dark;
      }
    }
  }

  .visual-select-icon {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .yb-icon {
      width: 100%;
      height: auto;
    }
  }

  .visual-select-text {
    flex-shrink: 0;
    padding-bottom: $space-xs;
    justify-self: flex-end;
  }

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
    color: $yembo-blue;

    &:hover,
    &.active {
      @include ghost-styles($border: 1px solid $yembo-blue-dark, $background: none, $color: $yembo-blue-dark);
    }
  }
}
