.kepler-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: $space-md;
  border-bottom: 1px solid $gray-border;

  h2 {
    color: $header-color;
    padding-right: $space-xs;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .left-container,
  .right-container {
    display: flex;
    align-items: center;
  }

  .left-container {
    justify-self: flex-start;
  }

  .right-container {
    justify-self: flex-end;
  }

  .action-section {
    .yb-primary-button,
    .yb-secondary-button {
      margin: 0;
    }
  }
}
