.property-details {
  max-width: 304px;
  padding: 0 $space-sm;

  .dropdown {
    padding-top: 0px;
  }

  .state-wrapper {
    display: flex;
    gap: $space-md;
    justify-content: flex-start;
    overflow: hidden;

    & > :first-child {
      flex-grow: 1;
      min-width: 30%;
      max-width: 70%;
    }

    .state {
      width: 80px;
    }

    .zip {
      width: 100px;
    }

    .textfield-error {
      white-space: nowrap;
    }
  }
}
