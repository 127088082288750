.action-with-employee-dialog {
  .modal-content-container {
    width: 576px;
  }

  .action-name-text,
  .action-description-text {
    margin-bottom: $space-sm;
  }

  .input-wrapper {
    padding: 0 0 $space-sm $space-lg;
    width: 46%;

    .yb-text-field {
      .textfield-input-wrapper.error {
        svg * {
          stroke: $danger-base;
        }
      }
    }
  }

  .yb-button {
    width: 145px;
  }
}
