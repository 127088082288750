.image-magnifier {
  cursor: none;
  .magnifying-image {
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    &.show {
      opacity: 1;
    }
  }
  .magnification {
    position: absolute;
    pointer-events: none;
    background-color: #191b1e; /* stylelint-disable-line declaration-property-value-allowed-list */
    background-repeat: no-repeat;
    border: 1px solid #dce0ef,
  }
}
