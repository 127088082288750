.insured-info {
  padding-left: $space-sm;
  flex: 1;

  &.scrollable-div {
    overflow-y: scroll;
    margin-right: -$space-xs;
    padding-right: $space-xs;
  }

  .insured-info-section {
    margin-bottom: $space-xs;

    h3.section-header {
      font-size: 14px;
      margin: $space-md 0 $space-sm 0;
    }

    .section-content {
      padding: 0 $space-md 0 $space-sm;

      .yb-text-field,
      .yb-text-field-phone {
        margin-top: $space-xs;
      }

      .member-since {
        padding-bottom: $space-xxs;

        &:focus-within .textfield-label {
          color: $body-color;
        }

        .textfield-error {
          display: none;
        }
      }
    }
  }
}
