.auth-header {
  text-align: start;

  .header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    transition: $ui-transition all 1s;

    h1 {
      display: flex;
      align-self: bottom;
    }

    #yembo-logo {
      margin-right: $space-xs;
      display: flex;
      margin-left: $space-xs;
    }
  }
  .auth-caption {
    padding-top: $space-xs;
    padding-bottom: $space-xl;
  }
}
