.zebra-table-container {
  padding: $space-sm $space-md $space-md;

  .kepler-zebra-table {
    width: 100%;
    max-height: 360px;
    overflow: scroll;
    border-collapse: collapse;
    table-layout: fixed;
    padding-left: $space-md;

    .kepler-table-row-wrapper {
      width: 100%;
      height: 40px;
      text-align: left;
      padding-left: $space-md;

      &.header {
        .tr {
          background: $white;
        }
      }

      &.body-row {
        max-height: 40px;

        tr:nth-child(odd) {
          background-color: $gray-background-light;

          &:hover {
            .textfield-input-wrapper {
              background-color: $white;
              border-color: transparent;
            }
          }

          &:active,
          &:focus,
          &:focus-within {
            .textfield-input-wrapper {
              background-color: $white;
              border-color: $yembo-blue;
            }
          }
        }

        tr:nth-child(even) {
          &:hover {
            .textfield-input-wrapper {
              background-color: $gray-background-light;
              border-color: transparent;
            }
          }

          &:active,
          &:focus,
          &:focus-within {
            .textfield-input-wrapper {
              background-color: $gray-background-light;
              border-color: $yembo-blue;
            }
          }
        }
      }
    }

    tr {
      max-height: 40px;

      td:nth-child(1) {
        width: 20px;
        padding: $space-sm $space-xs $space-sm $space-md;
        cursor: pointer;
      }
      td:nth-child(2) {
        width: 35%;
        padding: $space-sm $space-md;
      }

      td:nth-child(3) {
        width: 10%;
        padding: $space-sm $space-md;
      }

      td:nth-child(4) {
        width: 50%;
        padding: $space-sm $space-md;
      }

      th {
        text-align: left;
        padding: $space-sm $space-md;
      }
    }
  }
}
