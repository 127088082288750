@import './components/AnimatedLine';

.progress-bar-wrap {
  display: flex;
  flex-direction: column;
  position: relative;

  @media #{$screen_mobile_all_orientations} {
    display: none;
  }
  .progress-bar-position {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    @media #{$screen-tablets-portrait} {
      flex-direction: row;
    }

    .step-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      @media #{$screen-tablets-portrait} {
        width: 155px;
        flex-direction: row;
      }

      .progress-bar-fill {
        position: absolute;
        top: 0;

        @media #{$screen-tablets-portrait} {
          top: inherit;
        }

        &.not-active {
          width: 0px;
          height: 0px;
          background-color: $gray-background;
          transition: $ui-transition all 0.5s;
        }
        &.active {
          background-color: $yembo-blue;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          transition: $ui-transition all 0.5s;
        }
      }

      .circle {
        background-color: $gray-background;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;

        .yb-icon {
          z-index: 1;
        }

        @media #{$screen-tablets-portrait} {
          margin-left: $space-xs;
        }
      }

      &:first-of-type {
        .circle {
          background-color: $yembo-blue;
        }
      }
    }
  }
}
