@import './FilterPanel';
@import './InsuredSearch';

.overview-listing-screen {
  .content-container {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    padding: 0;
    box-shadow: 10px 4px 20px rgb(0 0 0 / 10%);
  }
}

.insured-empty-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.insured-listing {
  flex: 1 1 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: flex-shrink 0.2s linear;
  background: $white;
  border-radius: $space-md;
  box-shadow: 0 4px 20px rgb(0 0 0 / 10%);

  .insured-listing-content {
    display: flex;
    height: 100%;
  }

  .search-chips {
    display: flex;
    flex-wrap: wrap;
    row-gap: $space-sm;
    margin: $space-md $space-md 0 $space-md;

    .yb-chip {
      color: inherit;
      margin-right: $space-sm;
      max-width: 200px;
      border: none;
      background-color: #f0f7ff; /* stylelint-disable-line declaration-property-value-allowed-list */

      .chip-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .filter-group {
          margin-right: $space-sm;
        }
      }

      .chip-close-icon {
        span:hover {
          background-color: $menu-item-selected;
          border-radius: $space-xxs;
        }
      }

      &:hover {
        background-color: $menu-item-hover;
        box-shadow: 0 0 6px rgba(11, 82, 183, 0.09);
      }

      &:active {
        background-color: $menu-item-selected;
      }
    }
  }

  .kepler-header {
    margin: $space-md $space-md 0 $space-md;

    .insureds-list-action {
      margin-left: $space-sm;
      width: 112px;
      text-align: center;

      .chip-label {
        width: 100%;
      }
    }
  }

  .move-content-wrap {
    height: 100%;
    margin-top: $space-sm;
    overflow: auto;
    display: flex;
  }

  .centered-content {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .no-moves-text {
    margin-top: $space-md;
    color: $muted-text-color;
  }

  .spinner-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .kepler-spinner {
      height: 80px;
      width: 80px;
    }
  }

  .empty-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .grid-table-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    flex: 1 1;
    margin-bottom: $space-md;

    .grid-head-row {
      display: grid;
      grid-template-columns: 25% 21% 14% 15% 21% 4%;
      background: $white;
      color: $header-color;
      font-weight: 600;
      height: 48px;
      align-items: center;
      margin: $space-sm $space-md 0 $space-md;
      min-width: 653px;

      &.eight-columns {
        grid-template-columns: $space-lg auto 15% 15% 15% 16% 16% 4%;
      }

      &:not(.eight-columns) {
        padding-left: $space-lg;

        .grid-cell {
          cursor: pointer;

          &:hover {
            .arrows-container {
              visibility: visible;
            }
          }
        }
      }

      .arrows-container {
        $arrow-height: 8px;

        display: flex;
        flex-direction: column;
        margin-left: $space-xs;
        visibility: hidden;

        .arrow-wrapper {
          width: 12px;
          height: 12px;
          display: flex;
          border-radius: 1px;
          transition: $ui-transition background-color;

          .arrow-up,
          .arrow-down {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            margin: auto;
          }

          .arrow-up {
            border-bottom: $arrow-height solid #dce0ef;
          }

          .arrow-down {
            border-top: $arrow-height solid #dce0ef;
          }

          &:hover {
            background-color: $menu-item-hover;
          }
        }

        &.active {
          visibility: visible;

          &.asc {
            .arrow-up {
              border-bottom: $arrow-height solid $yembo-blue;
            }
          }

          &.desc {
            .arrow-down {
              border-top: $arrow-height solid $yembo-blue;
            }
          }
        }
      }
    }

    .grid-table-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 0 $space-xs 0 $space-md;
      overflow: hidden;
      min-width: 653px;

      .grid-row-wrapper {
        padding: $space-xs $space-sm $space-xs 0;
      }

      .grid-row {
        display: grid;
        flex: 1 1;
        grid-template-columns: 25% 21% 14% 15% 21% 4%;
        background: $white;
        box-sizing: border-box;
        border: 1px solid $gray-border;
        border-radius: $border-radius-md;
        transition: $ui-transition box-shadow;
        align-items: center;
        padding: $space-xs * 3 0;
        cursor: pointer;

        .archive-button {
          visibility: hidden;
        }

        &:hover {
          box-shadow: $z-depth-sm;

          .archive-button {
            visibility: visible;
          }
        }

        &.eight-columns {
          grid-template-columns: $space-lg auto 15% 15% 15% 16% 16% 4%;
        }

        &:not(.eight-columns) {
          padding-left: $space-lg;
        }
      }

      > .scrollbar-light-theme {
        min-width: 900px;
      }
    }

    .grid-cell {
      padding: 0 $space-md;
      display: flex;
      align-items: center;
      height: 100%;
      text-overflow: ellipsis;

      .assignee {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .danger {
        color: $danger-base;
        position: relative;
        left: 5px;
      }

      .name-field {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .yb-tool-tip {
        padding-left: $space-sm;

        &.hidden {
          visibility: hidden;
        }

        .tooltip-content {
          transform: translate(15px, -45px);
        }
        .progress-icon svg * {
          stroke: $info-base;
        }
      }

      &.right-aligned {
        justify-content: flex-end;

        &.cell-column {
          align-items: flex-end;
          text-align: end;
        }
      }

      &.no-projects {
        margin-right: $space-xl;
      }

      .archive-button {
        &:hover {
          &.yb-icon svg * {
            stroke: $danger-base;
          }
        }
      }

      &.cell-column {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        max-width: 100%;
      }
    }
  }

  .item-dot {
    width: $space-sm;
    height: $space-sm;
    border-radius: 50%;
    background: $yembo-blue;
    margin-right: $space-md - $space-xs;
    flex-shrink: 0;
  }

  .fetching-new-data {
    position: absolute;
    bottom: 0;
    left: $space-md;
    right: $space-md;
    background: $white;
    text-align: center;
    padding-top: $space-md;
  }
}

.insured-listing-cta-modal {
  .modal-content-container {
    width: 468px;
  }
  .modal-subheader-secondary-text {
    margin-top: $space-sm;
  }
}
