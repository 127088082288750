.context-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: $z-index-menu;
  width: 244px;
  background-color: $dm-background-light;
  border-radius: 0 $border-radius-md $border-radius-md 0;

  $border: 1px;

  &.main {
    transition: transform 750ms ease-in-out;
    animation: menuIn 0.125s;
    &.menu-in-left {
      transform-origin: top right;
    }
    &.menu-in-right {
      transform-origin: top left;
    }
  }

  .item {
    padding: $space-sm;
    color: $white;
    border: solid $border transparent;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    display: flex;
    transition: $ui-transition background-color;

    &.danger-item {
      color: $danger-light;
      .sub-menu-item {
        color: $danger-light !important;
      }
    }

    &:first-child {
      border-top-right-radius: $border-radius-md;
    }

    &:last-child {
      border-bottom-right-radius: $border-radius-md;
    }

    &.selected {
      background-color: $menu-item-hover;
    }

    .sub-menu-item {
      width: 100%;
      display: flex;
      align-items: center;
      color: $gray-background;
      > :first-child {
        flex: 1;
      }

      > svg:last-child {
        height: 7px;
        width: 7px;
      }
    }
  }

  .sub-menu {
    transition: transform 750ms ease-in-out;
    animation: menuIn 0.125s;
    box-shadow: -3px 3px 15px -1px black;

    &.right {
      left: calc(100% + 1px);
      transform-origin: center left;
    }
    &.left {
      transform-origin: center right;
      right: calc(100% + 1px);
    }
    &.no-search {
      top: -1px;
    }
    &.w-search {
      top: -53px; // height of search bar
    }
  }
  .sub-menu-item-list {
    max-height: 290px;
    overflow: auto;
    transform-origin: center top;

    .filtered {
      animation: filterIn 0.125s;
    }
  }

  @keyframes kepler-show-menu {
    to {
      opacity: 1;
    }
  }
}

@keyframes menuIn {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(0.4);
  }

  40% {
    transform: scale(0.7);
  }

  60% {
    transform: scale(0.8);
  }

  80% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes filterIn {
  0% {
    transform: scale(0.5);
    transform: translateY(40%);
  }
  20% {
    transform: scale(0.6);
    transform: translateY(30%);
  }

  40% {
    transform: scale(0.7);
    transform: translateY(20%);
  }

  60% {
    transform: scale(0.8);
    transform: translateY(10%);
  }

  80% {
    transform: scale(0.9);
    transform: translateY(5%);
  }

  100% {
    transform: scale(1);
    transform: translateY(0%);
  }
}

.kepler-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: $z-index-menu;
  width: max-content;
  background: $dm-background-light;
  border-radius: $border-radius-md;

  $border: 1px;

  .view-menu-contents {
    margin: $space-md $space-lg;
    .view-menu-title {
      margin-bottom: $space-sm;
    }
    .view-list {
      margin-left: $space-sm;
    }
  }
  .item {
    padding: $space-sm;
    color: $white;
    border: solid $border transparent;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    display: flex;

    &:first-child {
      border-top-right-radius: $border-radius-md;
    }

    &:last-child {
      border-bottom-right-radius: $border-radius-md;
    }

    &:hover,
    &.selected {
      border: solid $border $yembo-blue;
      background: $yembo-blue;
    }

    .sub-menu-item {
      width: 100%;
      display: flex;
      align-items: center;

      > :first-child {
        flex: 1;
      }

      > svg:last-child {
        height: 7px;
        width: 7px;
      }
    }
  }

  .sub-menu {
    top: -$border;
    left: calc(100% + 5px);
  }

  @keyframes kepler-show-menu {
    to {
      opacity: 1;
    }
  }

  opacity: 0;
  animation: kepler-show-menu 0.5s ease-in forwards;
}
