@mixin shimmer-after-styles {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));
  animation: shimmer 2s infinite;
  content: '';
}

.skeleton-cell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 $space-md;

  &.center-aligned {
    justify-content: center;
  }
  &.right-aligned {
    justify-content: right;
  }

  .content {
    min-height: 20px;
    border-radius: $space-xxs;
    position: relative;
    overflow: hidden;

    &::after {
      @include shimmer-after-styles;
    }
  }
}

.skeleton-badge {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  &.center-aligned {
    justify-content: center;
  }
  &.right-aligned {
    justify-content: right;
  }

  .content {
    min-height: 24px;
    border-radius: 100px;
    position: relative;
    overflow: hidden;

    &::after {
      @include shimmer-after-styles;
    }
  }
}

.skeleton-input {
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: $space-xs;

  &.center-aligned {
    align-items: center;
  }
  &.right-aligned {
    align-items: flex-end;
  }

  .content {
    border-radius: $space-xxs;
    min-height: 24px;
    position: relative;
    overflow: hidden;
    width: 100%;

    &::after {
      @include shimmer-after-styles;
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
