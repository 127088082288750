.add-task-popover {
  min-width: 306px;
  top: 52px;
  left: 8px;
  box-sizing: border-box;

  .popover-content {
    padding: 0 $space-sm;
    display: flex;
    flex-direction: column;
    gap: $space-sm;

    label {
      font-weight: 500;
    }

    .yb-dropdown {
      width: 196px;
    }

    .dropdown {
      padding-top: 0;
    }

    .description-label {
      margin-top: $space-xs;
      display: block;

      &:focus-within {
        color: $yembo-blue;
      }

      .task-description {
        margin: $space-xs 0 0 0;
        display: block;
        resize: none;
      }
    }

    .save-button {
      align-self: flex-end;
      margin: $space-lg 0 $space-sm 0;
      padding: 0 $space-xl + $space-sm;
    }
  }
}
