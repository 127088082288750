@import './CreateProjectModal/';
@import './InsuredInfo/';
@import './ProjectItem/';

.insured-overview {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 296px auto;
  grid-template-rows: 100%;
  height: 100%;

  .col-one {
    display: flex;
    flex-direction: column;
    border-radius: $space-md;
    box-shadow: 10px 4px 20px rgb(0 0 0 / 10%);
  }

  .col-two {
    display: flex;
    flex-direction: column;
    border-radius: $space-md;
    box-shadow: 0 10px 20px rgb(0 0 0 / 10%);

    .insured-overview-header {
      padding-bottom: $space-md;
    }

    .insured-actions {
      display: flex;
      padding-bottom: $space-sm;

      .yb-secondary-button {
        margin-right: $space-xs * 3;
        min-width: 243px;

        &:hover {
          background-color: #f0f7ff; /* stylelint-disable-line declaration-property-value-allowed-list */

          .yb-icon {
            svg * {
              stroke: #0c5ac9;
            }
          }
        }
      }
    }

    .no-projects-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.empty-state-wrapper {
        transform: translate(0, calc(-1 * #{$space-lg} / 2));

        svg {
          margin-bottom: $space-md;
        }
      }
    }

    .projects-container {
      height: 100%;
      overflow-y: scroll;
      padding-right: $space-sm;
      margin-right: -$space-xs;
      margin-top: $space-md;

      .project-section-container {
        &.second-section {
          margin-top: $space-xl;
        }
      }

      .grid-head-row {
        display: grid;
        grid-template-columns: $space-md 15% 24% 20% auto 21%;
        background: $white;
        color: $header-color;
        font-weight: 600;
        height: 48px;
        align-items: center;
        margin-top: $space-xs;
        min-width: 653px;

        .center-aligned {
          text-align: center;
        }

        > div {
          padding: 0 $space-md;

          &.hidden {
            visibility: hidden;
          }
        }
      }
    }
  }

  .overview-content-container {
    background-color: $white;
    padding: $space-md $space-sm $space-md $space-md;
    border-radius: $space-md;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    user-select: text;
    box-sizing: border-box;
    flex: 1;
  }
}
