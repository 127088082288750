.expanded-email {
  .email-fields-wrap {
    margin: $space-sm;
    position: relative;
  }

  .yb-tertiary-button {
    .tool-icon {
      svg * {
        fill: #19232e;
        stroke: none;
      }
    }

    &.loading {
      .button-content {
        height: 100%;
      }
    }
  }

  .yb-primary-button {
    margin-left: $space-lg;
  }

  .email-message-editor {
    &.focus {
      .tox-tinymce {
        border: 1px solid $yembo-blue;
      }
    }
    .tox-tinymce {
      border: 1px solid $gray-border;

      .tox-editor-container {
        flex-direction: column-reverse;
      }
      .tox-editor-header {
        width: 95%;
        border-top: 1px solid $gray-border;
        margin-left: auto;
        margin-right: auto;
        box-shadow: none;
      }
      .tox-statusbar {
        display: none;
      }
    }
  }
}
