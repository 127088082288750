.address-item {
  border: 1px solid #dce0ef;
  border-radius: $border-radius-sm;
  .grid-head-row {
    display: grid;
    grid-template-columns: auto 9% 9% 15% 28% 72px;
    background: $white;
    color: $header-color;
    font-weight: 600;
    height: 48px;
    align-items: center;
    min-width: 653px;
    .grid-head-cell {
      margin: 0 $space-md;
    }
  }
  .grid-address-row {
    display: grid;
    grid-template-columns: auto 9% 9% 15% 28% 72px;
    background-color: $gray-background-light;
    align-items: center;
    box-sizing: border-box;
    height: 64px;

    .address {
      transition: 0.2s ease-in-out color;
      &.editable {
        cursor: pointer;
        color: $yembo-blue;
        font-weight: 500;
        &:hover {
          color: $yembo-blue-dark;
        }
      }
    }

    .project-grid-cell {
      align-items: center;
      margin: 0 $space-md;
      .options-button {
        transition: 0.2s ease-in-out;
        padding: $space-sm;
        border-radius: $border-radius-sm;

        &:hover {
          background-color: $menu-item-hover;
        }
      }
      .textfield-input-wrapper {
        border: 1px solid transparent;
        transition: $ui-transition border;
      }
      &.area-cell {
        display: flex;
        width: calc(100% - $space-lg);
        &:not(:focus-within):hover * {
          cursor: pointer;
        }
        .area-textfield {
          width: 100%;
          padding: $space-sm 0 0 0;
          .textfield-input-wrapper {
            display: flex;
            align-items: center;
            font-size: $body-text;
            padding: $space-xs + $space-xxs $space-sm;
            border-radius: $border-radius-md;
            box-sizing: border-box;

            &:focus-within {
              border: 1px solid $yembo-blue;
            }
            .textfield-input {
              text-align: right;
              background-color: transparent;
              margin-right: $space-xs;
            }
          }
        }
      }

      &.status {
        text-transform: capitalize;
      }
    }

    &:hover {
      .textfield-input-wrapper {
        border: 1px solid #a1b2bf;
      }
    }
  }
}
