@import './components/Phone';
@import './components/AuthHeader';

.activate-page-container {
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
  background-color: $yembo-navy;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;

  .activate-page-content {
    padding: $space-md $space-xxl $space-md $space-xxl;
    display: flex;
    height: 616px;
    background-color: $white;
    border-radius: $border-radius;
    position: relative;
    flex-direction: row;
    justify-content: center;
    box-shadow: $muted-container-box-shadow;

    @media #{$screen-tablets-portrait} {
      width: 488px;
      height: 672px;
      flex-direction: column;
      box-sizing: border-box;
    }

    @media #{$screen_mobile_all_orientations} {
      width: 90%;
      flex-direction: column;
    }

    .activate-page-body {
      display: flex;
      column-gap: 70px;
      flex-direction: row;
      align-items: top;
      justify-content: center;

      @media #{$screen-tablets-portrait} {
        align-items: center;
        flex-direction: column;
      }
      .activate-page-form {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 350px;

        @media #{$screen-tablets-portrait} {
          order: -1;
        }
        .textfield-input-wrapper {
          margin-bottom: $space-xs;
        }
        .next-password-button,
        .next-phone-button {
          align-self: center;
          width: 100%;
        }
        .next-password-button {
          margin-top: $space-xl;
        }
        .next-phone-button {
          margin-top: $space-xxl + $space-xl;
          margin-bottom: $space-md;
        }
        .do-later-button {
          .button-content {
            .button-text {
              color: $yembo-blue;
            }
          }
        }
        .activate-page-form-inputs {
          text-align: left;
        }
        .agreement-text {
          margin-top: $space-xxl;
          width: 328px;
          text-align: left;
          color: $muted-text-color;
          line-height: $space-md;
        }
        svg {
          align-self: center;
        }
        .phone-input {
          margin-top: $space-xs;
        }
      }
      .success-text {
        width: 328px;
        padding-top: $space-xs;
        margin-top: $space-lg;

        @media #{$screen_mobile_all_orientations} {
          width: 300px;
        }
      }
      .success-button {
        width: 100%;
        align-self: center;
        margin-left: $space-lg;
        margin-top: $space-xxl + $space-xxl;
        margin-bottom: $space-md;
      }
      .button-wrapper {
        @media #{$screen-tablets-portrait} {
          margin-bottom: $space-sm;
        }

        @media #{$screen_mobile_all_orientations} {
          margin-bottom: $space-sm;
        }
      }
      .re-enter-button {
        padding: $space-xs;
      }
    }
    .phone-body {
      @media #{$screen-tablets-portrait} {
        margin-top: $space-md;
      }

      @media #{$screen_mobile_all_orientations} {
        margin-top: $space-md;
      }

      .do-later-button {
        @media #{$screen-tablets-portrait} {
          margin-bottom: $space-xxl;
        }

        @media #{$screen_mobile_all_orientations} {
          margin-bottom: $space-xxl;
        }
      }
    }
    .error-image {
      margin-top: $space-lg;
      margin-bottom: $space-lg;
      @media #{$screen-tablets-portrait} {
        margin-top: $space-xl;
        margin-bottom: $space-xxl;
      }
      @media #{$screen_mobile_all_orientations} {
        margin-top: $space-xl;
        margin-bottom: $space-xxl;
      }
    }
    .error-message {
      margin-top: $space-xxl;
      @media #{$screen-tablets-portrait} {
        margin-bottom: $space-xxl;
      }
      @media #{$screen_mobile_all_orientations} {
        margin-bottom: $space-xxl;
      }
    }
  }

  .passwords-match {
    text-align: left;
    color: $success-base;
    line-height: $space-md;
    margin-top: -$space-sm;
    &.hide {
      visibility: hidden;
    }
  }
}
.copyright-new {
  color: $mobile-gray-background;
}
