$itv-dark: darken(#69a738, 10%); /* stylelint-disable-line declaration-property-value-allowed-list */
$contents-dark: darken(#d1bf74, 10%); /* stylelint-disable-line declaration-property-value-allowed-list */

.tag-container {
  position: absolute;
  cursor: pointer;
  transform: translate(-50%, -50%);
  z-index: $content-z-index;
  transition: 0.2s ease-in-out opacity;

  &.add {
    animation: stampIn 0.3s;
    animation-fill-mode: forwards;
  }

  &.delete {
    animation: stampOut 0.3s;
    animation-fill-mode: forwards;
  }

  &:hover {
    z-index: $content-z-index + 1;

    .tooltip > .tooltip-content {
      max-height: 56px;

      .label-bk {
        font-size: 0.85rem;
        max-width: 320px !important;
      }
    }

    .tooltip {
      .tooltip-content {
        .label-bk {
          background-color: $dm-background-mid;
        }

        &.hazard {
          background-color: $danger-dark;
        }
      }

      .arrow {
        &.hazard {
          background-color: $danger-dark;
        }
      }
    }
  }

  &.hazard {
    &:hover {
      svg path:nth-child(0),
      svg path:nth-child(3),
      svg path:nth-child(2) {
        fill: $danger-dark;
      }
    }
  }

  &.itv {
    .tooltip {
      .tooltip-content,
      .arrow {
        background-color: #69a738; /* stylelint-disable-line declaration-property-value-allowed-list */
      }
    }
    &:hover {
      svg path:nth-child(0),
      svg path:nth-child(2),
      svg path:nth-child(3) {
        fill: $itv-dark;
      }
      .tooltip {
        .tooltip-content,
        .arrow {
          background-color: $itv-dark; /* stylelint-disable-line declaration-property-value-allowed-list */
        }
      }
    }
  }

  &.contents {
    svg {
      path {
        fill: #d1bf74; /* stylelint-disable-line declaration-property-value-allowed-list */
      }
    }
    .tooltip {
      .tooltip-content,
      .arrow {
        background-color: #d1bf74; /* stylelint-disable-line declaration-property-value-allowed-list */
      }
    }
    &:hover {
      svg {
        path {
          fill: $contents-dark; /* stylelint-disable-line declaration-property-value-allowed-list */
        }
      }
      .tooltip {
        .tooltip-content,
        .arrow {
          background-color: $contents-dark; /* stylelint-disable-line declaration-property-value-allowed-list */
        }
      }
    }
  }
}
.tag-icon {
  position: relative;
  &.itv {
    right: -1px;
    path {
      transition: $ui-transition all;
    }
    rect {
      transition: $ui-transition all;
    }
  }

  &.haz {
    bottom: 1px;
    right: 2px;
    path {
      transition: $ui-transition all;
    }
  }

  &.con {
    bottom: 1px;
    path {
      transition: $ui-transition all;
    }

    circle {
      transition: $ui-transition all;
    }
  }
}

.tooltip {
  position: absolute;

  .tooltip-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $space-xxs;
    z-index: 2;
    border-radius: $space-xxs;
    span {
      color: $mobile-white;
      white-space: nowrap;
    }

    &.hazard {
      background-color: $danger-base;
    }

    .label-bk {
      background-color: $dm-background-mid;
      padding: 0 $space-xs;
      max-width: 90px;
      transition: $ui-transition all;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: keep-all;
      overflow: hidden;
      color: $white;
      font-size: 0.8rem;
      border-radius: $space-xxs;
    }
  }

  .arrow {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    z-index: -1;
    &.hazard {
      background-color: $danger-base;
    }
  }

  &.top {
    bottom: 138%;
    transform: translate(calc(-50% + 12px));

    .arrow {
      bottom: -5px;
      left: 50%;
      margin-left: -5px;
      transform: rotate(45deg);
    }
  }

  &.top-left {
    bottom: 100%;
    right: 16px;
    .arrow {
      right: 0;
      bottom: 0;
    }
  }

  &.top-right {
    bottom: 100%;
    left: 16px;
    .arrow {
      left: 0;
      bottom: 0;
    }
  }

  &.bottom {
    top: 165%;
    transform: translate(calc(-50% + 8px));
    .arrow {
      top: -5px;
      left: 50%;
      margin-left: -5px;
      transform: rotate(45deg);
    }
  }

  &.bottom-left {
    top: 100%;
    right: 16px;
    .arrow {
      top: 0;
      right: 0;
    }
  }

  &.bottom-right {
    top: 100%;
    left: 16px;
    .arrow {
      top: 0;
      left: 0;
    }
  }
}

@keyframes stampIn {
  0% {
    transform: scale(1.8);
  }
  20% {
    transform: scale(1.6), translate(-10%, -10%);
  }
  40% {
    transform: scale(1.3), translate(-20%, -20%);
  }
  60% {
    transform: scale(1.1), translate(-30%, -30%);
  }
  80% {
    transform: scale(0.8), translate(-40%, -40%);
  }
  100% {
    transform: scale(1), translate(-50%, -50%);
  }
}

@keyframes stampOut {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(1.3);
    opacity: 0.9;
  }
  60% {
    transform: scale(1.4);
    opacity: 0.8;
  }
  80% {
    transform: scale(1.5);
    opacity: 0.7;
  }
  100% {
    transform: scale(1.5);
    opacity: 0.5;
    display: none;
  }
}
