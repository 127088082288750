.slider-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  opacity: 0;
  background-color: $modal-overlay;
  animation: 0.4s ease-in forwards overlay-fade-in;

  @keyframes overlay-fade-in {
    to {
      opacity: 1;
    }
  }

  @keyframes collapse {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  .slider-container.fullscreen {
    padding: $space-lg * 3;
    box-sizing: border-box;
    gap: 24px;

    .slides-wrapper {
      flex-basis: calc(100% - 48px);
      background-color: transparent;
      transform: scale(0);
      animation: collapse 0.4s ease-in-out 0.4s forwards;

      .slide-wrapper {
        .slide-inner {
          &.image-magnifier {
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
    }

    .slider-controls {
      .slider-arrow {
        &:hover {
          background-color: rgba(#fff, 0.08); /* stylelint-disable-line declaration-property-value-allowed-list */
        }

        svg * {
          stroke: $white;
        }
      }

      .slider-dot {
        background-color: $dm-background-light;
        transition: $ui-transition background-color;

        &.active {
          background-color: $white;
        }
      }
    }
  }
}

.slider-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $space-md;

  .slider-mode-icon {
    $icon-size: 32px;

    position: absolute;
    top: 0px;
    right: 0px;
    width: $icon-size;
    height: $icon-size;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    cursor: pointer;
    background-color: rgba(18, 23, 29, 0.5); /* stylelint-disable-line declaration-property-value-allowed-list */
    transition: $ui-transition background-color;
    backdrop-filter: blur(2px);
    border-radius: 0 $border-radius-sm 0 2px;

    svg * {
      stroke: $white;
    }

    &:hover {
      background-color: $dm-background-dark;
    }
  }

  .slides-wrapper {
    max-height: 100%;
    width: 100%;
    border-radius: $border-radius-sm;
    position: relative;
    overflow: hidden;
    aspect-ratio: 1/1;

    .slide-wrapper {
      position: absolute;
      height: 100%;
      width: 100%;
      max-height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
      left: 0;
      opacity: 1;
      transition: left 0.4s ease-in-out, opacity 0.4s ease-in-out;

      &.prev {
        left: -100%;
        opacity: 0;
      }

      &.next {
        left: 100%;
        opacity: 0;
      }

      .slide-inner {
        position: relative;
        max-height: 100%;
        max-width: 100%;
        border-radius: $border-radius-sm;
        overflow: hidden;

        &.image-magnifier {
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .slide {
          max-height: inherit;
          max-width: 100%;
        }
      }
    }
  }

  .slider-controls {
    width: 100%;
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;

    &.hidden {
      visibility: hidden;
    }

    .yb-icon.slider-arrow {
      cursor: pointer;
      border-radius: 6px;
      height: 24px;
      width: 24px;
      display: flex;
      margin: 0 $space-xs;
      transition: $ui-transition background-color;

      svg {
        margin: auto;
      }

      &.hidden {
        visibility: hidden;
      }

      &:hover {
        background-color: rgba(#fff, 0.08); /* stylelint-disable-line declaration-property-value-allowed-list */
      }
    }

    .slider-dot {
      width: 8px;
      height: 8px;
      border-radius: $border-radius-sm;
      transition: $ui-transition background-color;
      cursor: pointer;
      background-color: #dce0ef; /* stylelint-disable-line declaration-property-value-allowed-list */

      &.active {
        background-color: $yembo-blue;
      }
    }
  }
}
