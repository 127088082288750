.progress-indicator {
  .progress-indicator-step-list {
    display: flex;
    flex-direction: row;
    margin-bottom: $space-xs;
    height: 25px;
    .progress-indicator-step {
      width: 24px;
      border-radius: 5px;
      transition: 0.2s ease-in-out background-color;
      cursor: pointer;

      &:hover {
        background-color: $dm-background-light;
      }
      .step-number-circle {
        height: 19px;
        width: 19px;
        border-radius: 50%;
        border: 1px solid;
        position: relative;
        top: 2px;
        left: 1px;
        &.active {
          border-color: $yembo-blue-dark-mode;
        }
        &.inactive {
          border-color: $dm-background-extra-light;
        }
        .step-number {
          font-size: $body-text;
          line-height: 142.8%;
          text-align: center;
          position: relative;
          &.active {
            color: $yembo-blue-dark-mode;
          }
          &.inactive {
            color: $dm-background-extra-light;
          }
        }
      }
      .step-icon {
        position: relative;
        top: 0.5px;
        right: 0.5px;
        &.completed {
          &.yb-icon {
            svg * {
              stroke: $success-light;
            }
          }
        }
        &.active {
          &.yb-icon {
            svg * {
              stroke: $yembo-blue-dark-mode;
            }
          }
        }
        &.failed {
          .yb-icon {
            svg * {
              stroke: $danger-light;
            }
          }
        }
      }
    }
    .progress-indicator-line {
      width: 122px;
      height: 0px;
      position: relative;
      top: 8px;
      margin: $space-xs;
      border-bottom: 1px solid;
      &.active {
        border-color: $yembo-blue-dark-mode;
      }
      &.inactive {
        border-color: $dm-background-extra-light;
      }
      &.completed {
        border-color: $success-light;
      }
    }
  }
}
