.tutorial-tooltip {
  position: absolute;
  z-index: $popup-z-index;
  border-radius: $border-radius-md;
  height: 224px;
  width: 464px;
  cursor: default;
  opacity: 0;

  @include content-fade-in;

  &.left {
    left: 55px;
    &::before {
      content: '';
      width: 0px;
      height: 0px;
      border: 0.8em solid transparent;
      position: absolute;
      left: -20px;
      top: 5%;
      border-right: 10px solid $white;
    }
  }

  .info-graphic {
    padding-top: $space-xs;
    background-color: $white;
    height: 266px;
    border-radius: $border-radius-md $border-radius-md 0 0;
  }

  .info-content {
    background-color: $gray-background-light;
    height: 176px;
    border-radius: 0 0 $border-radius-md $border-radius-md;
    padding: $space-lg $space-md $space-md $space-md;
    position: relative;
    display: flex;
    flex-direction: column;
    .title {
      color: $header-color;
      margin-bottom: $space-sm;
    }
    .content {
      color: $body-color;
    }
    .bottom-info {
      margin-top: auto;
      bottom: 16px;
      display: flex;
      flex: 1;
      flex-direction: column;

      .info-button-row {
        display: flex;
        flex-direction: row;
        bottom: 10px;
        margin-left: auto;
        align-content: flex-end;
        flex: 1;
        height: 20px;
        .yb-button {
          margin: 0;
          margin-top: auto;
        }
      }
      .info-footer {
        color: $muted-text-color;
        font-size: 11.2px;
        line-height: 142.8%;
      }
    }
  }
}
