.company-details {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid $gray-border;
  border-radius: $border-radius-lg;
  box-sizing: border-box;
  min-width: 306px;
  padding: $space-md 0 $space-md $space-md;
  margin: $space-md $space-md 0 $space-xs;
  overflow: auto;
  opacity: 0;
  animation: 0.7s ease-in 0.2s forwards fadeIn;

  @keyframes fadeIn {
    100% {
      opacity: 1;
    }
  }

  .close-panel-button {
    position: absolute;
    top: 6px;
    right: 6px;
  }

  .fields-group {
    padding: 0 $space-sm 0 $space-sm;
  }

  .mfa-section {
    display: flex;
  }

  .company-details-header {
    margin: 0 $space-md $space-xs 0;

    > p {
      text-transform: capitalize;
    }
  }

  .company-section-header {
    margin: $space-md 0 $space-sm 0;

    &.checkbox-header {
      margin-bottom: $space-md;
    }
  }

  .button-container {
    display: flex;
    justify-content: center;

    .yb-primary-button {
      min-width: 160px;
      margin: $space-md 0;
    }
  }

  .scrollable-div {
    padding-right: $space-xs;
    margin: $space-sm $space-sm $space-sm 0;
    overflow: hidden scroll;
  }

  .color-layout {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    margin-right: $space-xs;
  }
}
