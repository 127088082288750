.verify-backup-code {
  .fields-container {
    display: flex;
    flex-direction: column;
    &.locked {
      > * {
        pointer-events: none;
        opacity: 30%;
      }
    }
  }
  .login-btn {
    margin-bottom: $space-md !important;
  }
  .back-btn {
    height: 48px;
  }
}
