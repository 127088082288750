.add-image {
  min-width: 292px;
  max-width: 292px;
  height: 392px;
  margin: $space-md $space-sm $space-sm 0;
  border: 2px dashed $gray-border;
  background-color: $white;
  border-radius: $space-xs;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .upload-image-illustration {
    margin-bottom: $space-md;
  }
  &.active {
    * {
      pointer-events: none;
    }

    border-color: $yembo-blue;
    background-color: $yembo-blue-light;

    .upload-image-illustration {
      path {
        stroke: $yembo-blue;
      }
    }

    .caption-text,
    .select-file-button {
      opacity: 0;
      transition: unset;
    }
  }

  .caption-text {
    color: $gray-dark;
    margin-top: $space-xs;
  }

  .hidden-input {
    display: none;
  }
}
