.personal-info-wrapper {
  margin-top: $space-md;
  margin-bottom: $space-md;

  .header:nth-of-type(2) {
    margin-top: $space-md;
  }

  .inputs-wrapper {
    margin-top: $space-sm;

    .phone {
      label.tiny-text.error {
        color: $body-color;
      }

      .int-phone-input.error {
        border-bottom-color: $gray-border;

        &:focus {
          border-bottom-color: $yembo-blue;
        }
      }
    }

    .department {
      margin-top: $space-sm;
    }
  }

  .units-wrapper {
    margin-left: $space-sm;
    margin-top: $space-md;
  }
}
