@import './TagItem';

.image-item {
  height: 396px;
  margin: $space-md $space-sm $space-sm 0;
  box-shadow: $z-depth-sm;
  position: relative;
  flex-shrink: 0;

  &.portrait {
    width: 296px;
    .review-image {
      width: 296px;
    }
  }

  &.landscape {
    width: 528px;
    .review-image {
      width: 528px;
    }
  }

  .loading-block {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
    top: 30%;
    left: 50%;
    transform: translate(-50%, 0);

    .blue-spinner {
      width: 56px;
      height: 56px;
    }
    .processing-text {
      margin-top: $space-lg;
    }
  }

  .image-overlay {
    position: absolute;
    z-index: $content-z-index + 1;
    top: 0;
    left: 0;
    backdrop-filter: blur(20px);
    background-color: rgba($white, 0); /* stylelint-disable-line declaration-property-value-allowed-list */
    transition: $ui-transition background-color;
    border-radius: $border-radius-sm;
  }

  &.dimmed {
    overflow: hidden;

    .image-overlay {
      height: 100%;
      width: 100%;
      background-color: rgba($white, 0.7); /* stylelint-disable-line declaration-property-value-allowed-list */
    }
  }

  .review-image {
    box-shadow: $z-depth-sm;
    width: auto;
    height: 396px;
    border-radius: $border-radius-sm;
    transition: 0.2s ease-in-out opacity;
  }

  .yb-video-player {
    height: 100%;
    .controls {
      border-radius: 4px;
    }
  }

  .open-slider-button,
  .delete-image-button {
    $icon-size: 32px;

    position: absolute;
    width: $icon-size;
    height: $icon-size;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    cursor: pointer;
    background-color: rgba(18, 23, 29, 0.5); /* stylelint-disable-line declaration-property-value-allowed-list */
    backdrop-filter: blur(2px);
    transition: $ui-transition background-color;
    visibility: hidden;

    svg * {
      stroke: $white;
    }

    &:hover {
      background-color: $dm-background-dark;
    }
  }

  .open-slider-button {
    top: 0px;
    right: 0px;
    border-radius: 0 $border-radius-sm 0 2px;
  }

  .delete-image-button {
    right: 0;
    bottom: 0;
    border-radius: 2px 0 $border-radius-sm 0;

    &:hover {
      svg * {
        stroke: $danger-light;
      }
    }
  }

  &:hover {
    .open-slider-button,
    .delete-image-button {
      visibility: visible;
    }
  }
}
