.insured-overview {
  .grid-row-wrapper {
    display: flex;
    align-items: center;
    height: 56px;
    min-width: 653px;
    outline: none;
    outline-offset: 0;

    .grid-row {
      $gray-400: #a1b2bf;

      display: grid;
      flex: 1 1;
      grid-template-columns: $space-md 15% 24% 20% auto 21%;
      background: $white;
      border: 1px solid $gray-border;
      border-radius: $border-radius-md;
      transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
      align-items: center;
      height: calc(100% - #{$space-sm});
      cursor: pointer;

      &.highlighted {
        background-color: #f0f7ff; /* stylelint-disable-line declaration-property-value-allowed-list */
      }

      &.dimmed {
        opacity: 0.6;
      }

      .assignee-dropdown {
        border: 1px solid transparent;
        border-radius: $border-radius-sm;
        box-sizing: border-box;
        padding: $space-xs $space-sm;
        transition: $ui-transition border;

        .dropdown-text {
          padding-left: 0;
        }

        .yb-icon {
          visibility: hidden;
          svg * {
            stroke: $gray-400;
          }
        }
        &.open {
          border: 1px solid $yembo-blue;
          .yb-icon {
            visibility: visible;
          }
        }
      }

      &:hover {
        box-shadow: $z-depth-sm;

        .assignee-dropdown:not(.open) {
          border: 1px solid $gray-border;
          .yb-icon {
            visibility: visible;
          }
          &:hover {
            border: 1px solid $gray-400;
            .yb-icon {
              svg * {
                stroke: $gray-400;
              }
            }
          }
        }
      }

      .grid-cell {
        padding: 0 $space-md;
        display: flex;
        align-items: center;
        height: 100%;
        text-overflow: ellipsis;
        box-sizing: border-box;

        &.right-aligned {
          justify-content: flex-end;
        }

        &.centered-content {
          width: 100%;
          justify-content: center;
          align-items: center;
        }

        &.project-status {
          text-transform: capitalize;
        }

        .item-dot {
          width: $space-sm;
          height: $space-sm;
          border-radius: 50%;
          background: $yembo-blue;
          margin-right: $space-md - $space-xs;
          flex-shrink: 0;
        }

        .cell-column {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }
      .assignee-cell {
        padding: 0 $space-sm;
      }
    }

    .popover-menu {
      width: fit-content;
      .menu-item {
        white-space: nowrap !important;
      }
    }
  }
}
