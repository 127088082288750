.side-bar {
  flex: 0 0 $toolbar-size;
  display: flex;
  flex-direction: column;
  background: $dm-background-mid;
  width: $toolbar-size;
  z-index: 15;
  position: absolute;
  left: -100px;
  height: calc(100% - 40px);
  -webkit-box-shadow: inset 0px -1px 0px 1px $dm-background-light;
  -moz-box-shadow: inset 0px -1px 0px 1px $dm-background-light;
  box-shadow: inset 0px -1px 0px 1px $dm-background-light;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 0.1s;
  animation: slide 0.5s forwards;
  animation-delay: 0.1s;

  .tools-container {
    display: flex;
    flex-direction: column;
    flex: 6 1;

    .top-items {
      flex: 6 1;
    }
    .bottom-items {
      flex-direction: column;
      justify-content: flex-end;
    }
  }
  .tool-bar-divide {
    margin: $space-sm $space-sm - 1;
    border-bottom: 1px solid $dm-background-extra-light;
    border-radius: $border-radius;
  }
  .nav-container {
    margin-bottom: $space-sm;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}
