.claim-affected-areas {
  margin: 0 $space-sm;
  display: flex;
  flex-direction: column;
  gap: $space-sm;
  max-height: 236px;
  overflow-y: scroll;
  @include scrollbar-light-theme;

  .area-item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: $space-md;

    .area-item-checkbox {
      cursor: pointer;
    }
  }

  .yb-text-field {
    padding-top: 0;
    width: 220px;
    margin-left: $space-lg + $space-xs;
  }
}
