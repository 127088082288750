.basic-tooltip {
  position: absolute;
  z-index: $popover-z-index;
  border-radius: $border-radius-md;
  cursor: default;
  opacity: 0;
  width: fit-content;
  @include content-fade-in;

  &.left {
    left: 55px;
    &::before {
      content: '';
      width: 0px;
      height: 0px;
      border: 0.8em solid transparent;
      position: absolute;
      left: -20px;
      top: 25%;
      border-right: 10px solid $white;
    }
  }
  .tooltip-content {
    background-color: $white;
    border-radius: $border-radius-md;
    padding: $space-sm;
    position: relative;
    display: flex;
    flex-direction: column;
    white-space: nowrap;

    .title {
      color: $header-color;
      margin-bottom: $space-sm;
    }
    .content {
      color: $body-color;
      display: inline-block;
      padding: $space-xs;
    }
    .bottom-info {
      margin-top: auto;
      bottom: 16px;
      display: flex;
      flex: 1;
      flex-direction: column;

      .info-button-row {
        display: flex;
        flex-direction: row;
        bottom: 10px;
        margin-left: auto;
        align-content: flex-end;
        flex: 1;
        height: 20px;
        .yb-button {
          margin: 0;
          margin-top: auto;
        }
      }
    }
  }
}
