.kepler-progress-bar {
  display: flex;
  flex-direction: row;
  margin: $space-xxs 0px;
  .progress-bar-wrapper {
    height: 8px;
    display: flex;
    flex: 1;
    border-radius: 8px;
    background-color: #363f4c; /* stylelint-disable-line declaration-property-value-allowed-list */
    .progress-bar {
      height: 100%;
      background-color: var(--ybm-primary-color);
      transition: width 0.5s ease;
      &.finished {
        border-radius: 8px;
      }
      &.in-progress {
        border-radius: 8px 0px 0px 8px;
      }
    }
  }
  .progress-label {
    position: relative;
    bottom: 3px;
    font-style: normal;
    font-weight: normal;
    font-size: 11.2px;
    line-height: 142.8%;
    margin-left: $space-sm;
    .yb-icon {
      position: relative;
      bottom: 1px;
      svg * {
        stroke: $success-light;
      }
    }
  }
}
