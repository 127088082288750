#login-new {
  .resend-mfa {
    form {
      .yb-radio-button {
        padding-top: 0px;
        padding-bottom: $space-xl;

        .radio-label {
          top: 10px;
        }
      }
    }

    button.yb-primary-button {
      margin-top: 64px;
    }
  }
}
