.messages-input-wrap {
  border: 1px solid $gray-border;
  border-radius: $border-radius-lg;
  padding: $space-sm $space-md $space-xs $space-md;

  &:focus-within {
    border-color: $yembo-blue;
  }

  .messages-options-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    .character-count {
      position: absolute;
      bottom: -$space-xl;
      right: -$space-md;
    }

    .yb-button {
      margin: 0 $space-xs;

      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  .yb-text-area {
    border: none;
    border-bottom: 1px solid $gray-border;
    border-radius: 0;
    margin: 0 0 $space-xs;
    resize: none;
  }
}
