#login-new {
  .verify-mfa-form {
    .fields-container {
      display: flex;
      flex-direction: column;
      &.locked {
        > * {
          pointer-events: none;
          opacity: 30%;
        }
      }
    }
    .yb-code-input {
      padding: 0;
      margin-bottom: 0;
      margin-left: -$space-sm;
    }

    .resend-otc-text {
      padding: $space-lg 0;
    }

    button {
      &.default {
        margin: $space-xxl 0 calc($space-lg - $space-xxs) 0;
      }

      &.small {
        margin: 0;
        padding: 0;
      }
    }
    .backup-btn {
      height: 48px;
    }
  }
}
