.kepler-multiselect {
  position: relative;
  max-width: 100%;

  &.close {
    height: 0;
    padding: 0;
    overflow: hidden;
  }

  .multiselect-label {
    transition: $ui-transition color;
    font-weight: 500;

    .required {
      margin-left: $space-xs;
      color: $yembo-blue;
    }
  }

  .multiselect-input-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid $gray-border;
    max-width: 100%;
    transition: $ui-transition border-color;
    cursor: pointer;

    &:hover {
      border-color: #a1b2bf;
    }

    .placeholder {
      color: $muted-text-color;
      &.selected {
        color: $body-color;
      }
    }

    .yb-icon {
      &.dropdown-icon {
        transition: transform 0.3s ease-in-out;
        margin-left: auto;

        &.close {
          svg {
            path {
              stroke: $gray-dark;
            }
          }
        }

        &.open {
          transform: rotate(-180deg);

          svg {
            path {
              stroke: $yembo-blue;
            }
          }
        }
      }
    }

    .multiselect-textfield {
      &:focus-within {
        cursor: text;
      }

      &.full-width {
        width: calc(100% - 16px);
      }

      .textfield-error {
        display: none;
      }

      .textfield-input-wrapper {
        border-bottom: none;
        padding: 0;

        input {
          cursor: pointer;
        }

        &.full-width input {
          cursor: text;
        }
      }
    }

    .selected-option {
      background: $gray-background;
      border-radius: $space-xs;
      font-size: $tiny-text;
      height: 20px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: $space-xxs $space-xs $space-xxs 0;
      padding: 0 $space-xs 0 $space-sm;
      cursor: default;

      .selected-option-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 170px;
      }

      .yb-icon {
        margin-left: $space-md;
        cursor: pointer;

        svg {
          height: 17px;
          width: 17px;
        }

        &:hover {
          svg * {
            stroke: $yembo-blue-dark;
          }
        }
      }
    }
  }

  .multiselect-menu-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    overflow: hidden;
    border-radius: $border-radius-md;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
    z-index: $z-index-menu;
    background-color: $white;
    margin-top: $space-xs;
    left: -$space-sm;
    animation: open-dropdown 0.2s forwards;

    @keyframes open-dropdown {
      0% {
        max-height: 0;
      }
      100% {
        max-height: 176px;
      }
    }

    &.full-width {
      min-width: 100%;
    }

    .multiselect-menu {
      display: flex;
      flex-direction: column;
      flex-shrink: 1;
      overflow-y: scroll;
      margin: $space-sm $space-xs;

      &.scroll {
        .multiselect-menu-item:last-child {
          border-radius: 0;
        }
      }

      .multiselect-menu-item {
        cursor: pointer;
        margin: $space-sm;
        white-space: nowrap;
      }
    }
  }

  .multiselect-error {
    color: $danger-base;
    margin-top: $space-xs;
    height: auto;
    visibility: hidden;
  }

  &:focus-within {
    .multiselect-label {
      color: $yembo-blue;
    }

    .multiselect-input-wrapper {
      border-bottom: 1px solid $yembo-blue;
    }
  }

  &.disabled {
    opacity: 0.25;
    cursor: not-allowed;

    * {
      cursor: not-allowed !important;
    }
  }

  &.with-error {
    .multiselect-label {
      color: $danger-base;

      .required {
        color: inherit;
      }
    }

    .multiselect-input-wrapper {
      border-bottom: 1px solid $danger-base;
    }

    .multiselect-error {
      visibility: visible;
    }
  }

  &.dropdown-overlay {
    position: fixed;
    z-index: $popover-z-index;
    max-height: 0;
  }
}
