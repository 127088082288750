.review-sliding-drawer {
  position: absolute;
  height: calc(398px + #{$space-md} * 2);
  width: calc(296px + 40px + #{$space-md} * 2);
  top: 0;
  right: 0;
  display: flex;
  transition: transform 0.8s;
  z-index: $window-z-index;
  justify-self: end;

  .sliding-drawer-shadow-container {
    position: relative;
    flex-shrink: 0;
    height: 100%;
    width: 40px;
    background: $sliding-drawer-shadow;
    margin-left: $space-sm + $space-xs;
  }

  .sliding-drawer-content-wrapper {
    order: 2;
    flex-grow: 1;
    z-index: 1; // needs for correct box-shadow
    box-sizing: border-box;
    box-shadow: -4px 0 12px rgba(0, 0, 0, 0.1);
    background-color: $white;
    overflow: hidden;
    border-radius: 0 $space-md $space-md 0;

    .sliding-drawer-content {
      width: 296px;
      height: 396px;
      margin: $space-md;
    }
  }

  .sliding-drawer-collapse-button {
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: -4px 0 4px rgba(0, 0, 0, 0.1);
    padding: 0;
    order: 1;
    width: 20px;
    height: 32px;
    border-radius: 0 0 0 $border-radius-md;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    z-index: 1; // needs for correct box-shadow
    cursor: pointer;

    &:hover {
      background-color: $gray-background-light;
    }

    .yb-icon {
      transition: transform 1s;
      backface-visibility: visible;
    }
  }

  transform: translateX(0);

  &.collapsed {
    transform: translateX(calc(100% - 54px));

    .sliding-drawer-collapse-button {
      .yb-icon {
        transform: rotateY(180deg);
      }
    }
  }
}
