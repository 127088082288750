.yb-modal {
  &.mfa-modal {
    .modal-content-container {
      max-width: 512px;
      box-sizing: border-box;
      .modal-body-wrapper {
        padding: 0;
      }
    }
    .revoke-sessions-checkbox {
      margin-top: $space-md;
      margin-left: $space-md;
    }
  }
}
