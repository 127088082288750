@import './vars';
@import './ReportBuilder';
@import './BookmarkPanel/';
@import './ToolBars/SideToolBar';
@import './ToolBars/TopToolBar';
@import './ToolBars/ToolBarItem/';
@import './CameraControls';
@import './PosedImageViewer/';
@import './WelcomeModal/';
@import 'src/react/_components/Menu/';

.loading-kepler {
  background-color: #191b1e; /* stylelint-disable-line declaration-property-value-allowed-list */
  height: 92%;

  .animation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 40px;
    margin-top: 44px;
    .fake-loading-screen {
      height: 70%;
      width: 70%;
    }
  }

  .fake-toolbar {
    height: 40px;
    flex: 0 0 $toolbar-size;
    display: flex;
    background: $dm-background-mid;
    z-index: $z-index-menu;

    .fake-title {
      margin: auto;
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: row;
      color: $white;
      margin-right: 10px;
      .top-spin {
        margin-left: 10px;
      }
    }
  }
}

.kepler-editor {
  color: $white;
  font-family: Montserrat;
  background: #191b1e; /* stylelint-disable-line declaration-property-value-allowed-list */
  display: flex;
  flex-direction: column;
  height: 92%;
  user-select: none;

  .workspace-wrapper {
    flex: 1;
    display: flex;
    align-items: stretch;

    .workspace {
      flex: 1;
      display: flex;
      align-items: stretch;
      .fake-toolbar {
        height: 40px;
        width: 100vw; /* stylelint-disable-line declaration-property-unit-allowed-list */
        display: flex;
        background: $dm-background-mid;
        z-index: $z-index-menu;
        margin-bottom: 4px;
        .fake-title {
          margin: auto;
          display: flex;
          flex: 1;
          justify-content: flex-end;
          align-items: flex-end;
          flex-direction: row;
          color: $white;
          margin-right: 10px;
          .top-spin {
            margin-left: 10px;
          }
        }
      }

      .canvas-wrapper {
        margin-left: 40px;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        > canvas {
          position: absolute;
          outline: 0;
        }

        &.loading {
          margin-top: 4px;
          width: calc(100vw - 40px); /* stylelint-disable-line declaration-property-unit-allowed-list */
          height: 100vh;
          position: absolute;
        }

        .loading-screen {
          width: 70%;
          height: 70%;
          z-index: 100;
        }
      }
    }
  }

  .help-mode-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
    background: $modal-overlay;
  }

  .kepler-footer {
    opacity: 0;
    color: $gray-background;
    animation-delay: 1s;
    font-size: $tiny-text;
    z-index: 15;

    @include content-fade-in;

    > div {
      margin-top: 0px;
      position: fixed;
      right: $space-md;
      padding: $space-sm $space-xs $space-xs $space-xs;
    }
    .yb-footer {
      bottom: 5px;
    }
  }

  .top-bar-panel {
    position: absolute;
    background: $dm-background-mid;
    top: $toolbar-size + $space-sm;
    border-radius: $button-border-radius;

    @include content-fade-in;
  }
}

.activity-panel-edit-location-popover.cta-popover-window {
  .close-button,
  .popover-header {
    display: none;
  }

  .yb-radio-button {
    display: flex;
    align-items: center;
    height: 26px;
  }

  width: 100%;
  background-color: $dm-background-light;
  color: $white;
  padding: $space-sm;

  @include content-fade-in;
}

// bring Babylon Debuggers to front
#scene-explorer-host {
  z-index: 100;
}
#inspector-host {
  z-index: 100;
}

.dark-modal {
  .modal-content-container {
    box-sizing: border-box;
    max-width: 496px;

    .modal-subheader,
    .modal-subheader-secondary-text {
      padding-top: $space-sm;
      color: #ebf1f4; /* stylelint-disable-line declaration-property-value-allowed-list */
    }

    .modal-subheader {
      padding-bottom: $space-sm;
    }
  }
}
