.kepler-table-text-field {
  display: flex;
  align-items: center;
  background: transparent;

  .yb-text-field {
    flex: 1;

    .textfield-input-wrapper {
      height: 34px;
      border-radius: $border-radius-md;
      border-color: transparent;
      border-width: 1px;
      padding: 0;
      align-items: center;

      &:focus-within,
      &:active,
      &:focus {
        border-color: $yembo-blue;
      }
    }

    .textfield-input {
      background-color: transparent;
      border-radius: $border-radius-md;
      padding: $space-xs $space-md;
      text-overflow: ellipsis;
    }

    .textfield-error.hide {
      display: none;
    }
  }

  .yb-icon {
    margin: $space-xs $space-sm;
  }

  .yb-blue-spinner {
    margin: $space-xs $space-sm;
  }
}
