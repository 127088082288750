.top-slider {
  display: flex;
  flex-direction: row;
  position: relative;
  top: 10px;
  .slider-element {
    position: relative;
    bottom: 1px;
    margin: 0px $space-sm;
  }
}
