.media-card {
  min-width: 230px;
  min-height: 238px;
  border: 1px solid $gray-border;
  box-sizing: border-box;
  border-radius: $border-radius-md;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  cursor: pointer;

  &:hover {
    box-shadow: $z-depth-md;

    .preview-overlay {
      height: calc(100% - 1px);
      background-color: rgba($white, 0.7); /* stylelint-disable-line declaration-property-value-allowed-list */
    }
  }

  .preview-overlay {
    position: absolute;
    height: 0;
    width: 100%;
    overflow: hidden;
    background-color: $white;
    transition: $ui-transition background-color;
    backdrop-filter: blur(20px);
    border-radius: $border-radius-md $border-radius-md 0 0;

    .card-tooltips {
      display: flex;
      position: absolute;
      bottom: 0;
      right: 0;
      .yb-icon-only-button {
        margin: $space-sm;
        background: $white-muted;
      }
      .yb-blue-spinner {
        padding: $space-sm;
      }
    }
  }

  .card-preview-wrapper {
    width: 100%;
    height: 200px;
    position: relative;

    .underlay-around-video {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      border-bottom: 1px solid transparent;
      box-sizing: border-box;

      &.hide {
        display: none;
      }

      &.show {
        display: initial;
      }

      &.active {
        background-color: $white;
        background-image: linear-gradient(rgba(255, 255, 255, 0.87), rgba(255, 255, 255, 0.6));
      }

      &.inactive {
        background-color: $white;
        background-image: linear-gradient(rgba(255, 255, 255, 0.87), rgba(255, 255, 255, 0.6));
      }

      &.failed {
        background-color: $white;
      }

      &.processing {
        background-color: $white;
      }

      &.no-underlay {
        background-color: $white;
        color: $header-color;
        background-image: none;
        border-bottom: 1px dashed #dce0ef;

        .placeholder-icon {
          padding-top: $space-sm;
          svg {
            width: 130px;
            height: 130px;
          }
        }
      }

      .yb-blue-spinner {
        svg {
          width: 56px;
          height: 56px;
        }
      }

      .failed-icon {
        svg {
          width: 56px;
          height: 56px;
        }
      }

      .video-preview-image {
        height: 100%;
        width: 100%;

        &.show {
          display: initial;
        }

        &.hide {
          display: none;
        }

        img {
          margin: 0;
          z-index: -1;
          height: 100%;
          width: 100%;
          object-fit: cover;

          &.show {
            display: initial;
          }

          &.hide {
            display: none;
          }
        }
      }
    }
  }

  .card-name-wrapper {
    padding: $space-sm ($space-lg - $space-sm) $space-md $space-sm;

    .card-name {
      display: flex;
      padding: $space-xxs 0;
      &::before {
        content: '';
        min-width: $space-sm;
        height: $space-sm;
        border-radius: $border-radius-sm;
        margin: $space-xs + $space-xxs $space-xs;
      }
      &.with-blue-dot::before {
        background: $yembo-blue;
      }
    }
  }
}
