.survey-list {
  border: 1px solid #dce0ef;
  border-radius: $border-radius-sm;
  margin: $space-sm 0;
  .grid-head-row {
    display: grid;
    grid-template-columns: auto 15% 28% 72px;
    background: $white;
    color: $header-color;
    font-weight: 600;
    height: 48px;
    align-items: center;
    min-width: 653px;
    .grid-head-cell {
      margin: 0 $space-md;
    }
    &.admin-view {
      grid-template-columns: auto 9% 15% 28% 72px;
    }
  }
  .grid-survey-row {
    display: grid;
    grid-template-columns: auto 15% 28% 72px;
    background-color: $white;
    align-items: center;
    padding: calc($space-md - $space-xs / 2) 0px;
    transform: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;

    &:nth-of-type(even) {
      background-color: $gray-background-light;
      .resend-icon {
        transition: 0.2s ease-in-out;
        &:hover {
          svg {
            background-color: $menu-item-hover;
            border-radius: $border-radius-sm;
          }
        }
      }
    }

    &.admin-view {
      grid-template-columns: auto 9% 15% 28% 72px;
    }
  }

  &.admin-view {
    grid-template-columns: auto 9% 15% 28% 72px;
  }

  &.highlighted {
    background-color: #f0f7ff; /* stylelint-disable-line declaration-property-value-allowed-list */
  }

  &.dimmed {
    opacity: 0.6;
  }

  .project-grid-cell {
    align-items: center;
    margin: 0 $space-md;

    .resend-icon {
      visibility: hidden;
      opacity: 0;
      transition: $ui-transition opacity;
      border-radius: $border-radius-sm;
      cursor: pointer;
      position: relative;
      height: 16px;

      &:hover {
        svg * {
          stroke: $yembo-blue;
        }
      }

      svg {
        position: absolute;
        padding: $space-sm;
        top: calc(0px - $space-sm - $space-xs);
      }
    }
    &.status {
      text-transform: capitalize;
    }
  }
  &:hover {
    .resend-icon {
      visibility: visible;
      opacity: 1;
    }
  }
}

.resend-invite-modal {
  &.send-invite-screen {
    .modal-content-container {
      max-width: 536px;
      width: fit-content;
    }

    .modal-footer-wrapper {
      margin-top: $space-sm !important;
    }
  }
}
