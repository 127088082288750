@import './Admin';
@import './Insured';
@import './Settings';
@import './CreateInsured';
@import './ErrorPage';
@import './Sidebar';
@import './Annotate';
@import './Reporting';
@import './AIDashboards';

// remove macOS "rubberbanding effect"
body {
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
}

.kepler-main-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  user-select: none;

  .placeholder-breads {
    height: 52px;
  }

  .spinner-wrapper-centred-style {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .kepler-spinner {
      height: 80px;
      width: 80px;
    }
  }
}
