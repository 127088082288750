.invite-send-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  @keyframes notification-appearing {
    from {
      max-height: 1px;
    }
    to {
      max-height: 56px;
    }
  }

  .notification {
    align-self: flex-start;
    animation: notification-appearing 0.5s forwards;
    max-height: 1px;
    overflow: hidden;
    box-sizing: border-box;
    margin: -$space-sm 0 $space-md 0;

    .notification-content {
      display: flex;
      flex-direction: column;
      border-radius: $border-radius-md;
      background-color: $gray-background;
      padding: $space-sm;
    }
  }

  .actions-wrapper {
    display: flex;
    justify-content: center;
    align-self: stretch;
    gap: $space-md;
    margin: $space-sm 0 $space-sm * 3 0;
  }

  .send-survey {
    svg {
      height: auto !important;
    }
  }

  .sending-error-icon {
    margin: $space-lg 0;
  }

  .invite-link {
    text-align: center;
    height: 24px;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
