.requirements-wrapper {
  .title {
    padding-top: $space-sm;
    padding-bottom: $space-sm;
  }

  .list {
    .requirement {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: $space-sm;

      .yb-icon {
        padding-right: $space-sm;

        path {
          stroke: $dm-muted-text-color;

          &:nth-child(2) {
            display: none;
          }
        }

        &.matched {
          path {
            stroke: $success-base;

            &:nth-child(2) {
              display: block;
            }
          }
        }
      }

      .summary {
        margin-top: 2px;
        line-height: normal;
      }
    }
  }
}
