.review-image {
  .circle-wrapper {
    border: $space-xxs solid $white;
    border-radius: 50%;
    box-shadow: 0px 0px 16px rgba($white, 0.62);
    padding: $space-xxs;

    .take-snapshot-button {
      background-color: $white;
      border-radius: 50%;
      width: $space-xl;
      height: $space-xl;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        box-shadow: 0px 0px 20px rgba($yembo-blue-light, 0.62);
      }

      &:disabled {
        opacity: 1;

        * {
          cursor: not-allowed;
        }
      }

      .yb-spinner {
        margin-right: 0;
      }

      span.yb-icon {
        width: $space-lg;

        path {
          stroke: $yembo-blue !important;
        }
      }
    }
  }
}
