.sms-container {
  align-self: flex-end;
  text-align: right;
  position: relative;
  margin-bottom: $space-lg;

  &.from-insured {
    align-self: flex-start;
    text-align: left;

    .text-message-container {
      border: 1px solid $gray-dark;
    }

    .text-message-timestamp {
      color: $gray-dark;
      left: $space-md;
    }
  }

  .text-message-container {
    width: 276px;
    border: 1px solid $yembo-blue;
    border-radius: $space-md;
    box-sizing: border-box;
    padding: $space-md;
    padding-top: $space-sm;
    position: relative;

    .text-message-sender {
      margin-bottom: $space-sm;
    }

    .text-message-text {
      user-select: text;
      overflow-wrap: break-word;
    }
  }

  .text-message-timestamp {
    color: $yembo-blue;
    position: absolute;
    right: $space-md;
    top: calc(100% + $space-xs / 2);
  }

  .img-container {
    width: 231px;
    margin-bottom: $space-sm;
    position: relative;

    &:hover {
      img {
        opacity: 0.3;
      }
      .img-overlay {
        opacity: 1;
      }
    }

    img {
      max-width: 100%;
      border-radius: $space-sm;
      opacity: 1;
      display: block;
      transition: 0.5s ease;
    }

    .img-overlay {
      transition: 0.5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 65%;

      .img-option-container {
        display: flex;
        color: $yembo-blue;
        align-items: center;
        margin-bottom: $space-md;
        cursor: pointer;

        a {
          color: $yembo-blue;
          text-decoration: none;
        }

        span {
          margin-left: $space-sm;
        }
      }
    }
  }
}
