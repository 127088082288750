@import './components/CompanyDetails';
@import './components/MfaModal';

.companies {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .tab-body {
    flex-grow: 1;
    width: 100%;
    overflow: hidden;

    .tab-body-columns {
      display: flex;
      height: 100%;
      flex-shrink: 0;
      flex-basis: auto;

      .empty-list {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .grid-table-wrapper {
      $grid-template-columns: 26% auto 26% 20% 56px;

      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: scroll hidden;
      max-width: calc(100% - 20px);
      margin: 0 $space-xs 0 $space-md;
      transition: flex 0.2s linear;
      flex-basis: 100%;
      flex-shrink: 0;

      &.collapse {
        flex-basis: calc(100% - 306px);
        flex-shrink: 1;
      }

      .logo {
        img {
          width: 80px;
          padding-left: $space-sm;
        }
      }

      .grid-head-row {
        display: grid;
        grid-template-columns: $grid-template-columns;
        background: $white;
        color: $header-color;
        font-weight: 600;
        height: 48px;
        align-items: center;
        margin: $space-xs $space-xs * 3 0 0;
        padding: $space-md 0;
        box-sizing: border-box;
        min-width: 700px;
      }

      .grid-table-body {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        overflow: hidden;
        min-width: 700px;

        .grid-row {
          display: grid;
          flex: 1 1;
          grid-template-columns: $grid-template-columns;
          background: $white;
          box-sizing: border-box;
          border: 1px solid $gray-border;
          border-radius: $border-radius-md;
          align-items: center;
          margin: $space-xs $space-sm $space-sm 0;
          padding: $space-xs * 3 0;
          cursor: pointer;
          transition: $ui-transition box-shadow;

          &:hover {
            box-shadow: $z-depth-md;

            .delete-cell {
              visibility: visible;
              overflow: visible;
            }
          }
        }

        > .scrollbar-light-theme {
          min-width: 900px;
        }
      }

      .grid-cell {
        padding: 0 $space-md;
        display: flex;
        align-items: center;
        height: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.right-aligned {
          justify-content: flex-end;

          .cell-column {
            align-items: flex-end;
            text-align: end;
          }
        }

        .cell-column {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }
    }
  }

  .add-button-container {
    display: flex;
    justify-content: flex-end;
    max-height: 80px;
    transition: max-height 0.2s;
    padding: 0 $space-md;

    .yb-button {
      padding: 0 $space-md;
      margin-top: $space-lg;
      margin-bottom: $space-sm;
    }

    &.hidden {
      max-height: 0;
    }
  }

  .delete-cell {
    padding-right: 19.5px;
    visibility: hidden;

    .yb-tool-tip {
      .tooltip-content {
        top: 80% !important;
      }
    }

    &:hover {
      svg * {
        stroke: $danger-base !important;
      }
    }
  }
}

.delete-company-dialog {
  .modal-content-container {
    width: 576px;
  }

  .action-name-text,
  .action-description-text {
    margin-bottom: $space-sm;
  }

  .input-wrapper {
    padding: 0 0 $space-sm $space-lg;
    width: 46%;

    .yb-text-field {
      .textfield-input-wrapper.error {
        svg * {
          stroke: $danger-base;
        }
      }
    }
  }

  .yb-button {
    width: 145px;
  }
}
