.analysis-in-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $space-xl;

  .text-wrapper {
    p {
      text-align: center;
      padding-bottom: $space-md;
    }
  }

  .video-wrapper {
    max-width: 597px;
    min-width: 0;
    border-radius: $space-sm;

    .analysis-in-progress-video {
      width: 100%;
    }
  }
}
