@import './ClassificationsModal';

.review-action-bar {
  padding-bottom: $space-md;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  .floating-region-notes {
    position: absolute;
    z-index: $floating-window-z-index;

    .region-notes-text-area-wrapper {
      width: 100%;
      height: 100%;
    }
    .region-notes-text-area {
      width: 100%;
      height: 100%;
      max-height: 100%;
      min-height: 50px;
      margin: 0;
      resize: none;
    }
  }

  .yb-secondary-button,
  .yb-primary-button {
    height: 32px;
    flex-grow: 1;
    min-width: 0;
  }

  .left-actions-wrapper,
  .right-actions-wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex: 1 1 auto;
    min-width: 0;
    max-width: 100%;

    .yb-tool-tip {
      display: flex;
      flex: 1 1 auto;
      max-width: 56px;
      min-width: 38px;
      align-items: center;
      justify-content: center;

      .tooltip-content {
        transform: translateY(5px);
      }

      &.status-tooltip {
        .tooltip-content {
          transform: translate(calc(-50% + #{$space-xs}), 5px);
        }
      }
    }
  }

  .left-actions-wrapper {
    justify-content: flex-start;
    .yb-tool-tip {
      margin-right: $space-sm;
    }

    #menu-burger-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: $space-md;

      .yb-popover-menu {
        z-index: $window-z-index;

        .clickable-element {
          .yb-icon {
            transform: rotate(90deg);
          }
        }

        .menu-item {
          padding: $space-sm - $space-xs $space-md - $space-xs;

          &:nth-child(2) {
            padding-bottom: $space-md - $space-xs;
            border-radius: 0 0 $border-radius-md $border-radius-md;

            @media #{$screen_tablets_portrait} {
              padding: $space-sm - $space-xs $space-md - $space-xs;
            }

            @media #{$screen_ipad_portrait} {
              padding: $space-sm - $space-xs $space-md - $space-xs;
            }
          }

          &:first-child {
            padding-top: $space-md - $space-xs;
          }

          &:last-child {
            padding-bottom: $space-md - $space-xs;
          }
        }

        .burger-menu-item {
          display: flex;
          align-items: flex-end;
          min-width: 240px;

          .burger-menu-item-text {
            flex-shrink: 0;
            padding-left: $space-sm;
          }
        }
      }
    }
  }

  .right-actions-wrapper {
    justify-content: flex-end;

    .yb-tool-tip {
      margin: 0 0 0 $space-sm;
    }
  }

  .select-region-action-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;

    .yb-dropdown.open + .tooltip-content {
      display: none;
    }

    .select-region-dropdown {
      width: 244px;

      @media #{$screen_tablets_portrait} {
        width: auto;
      }

      @media #{$screen_ipad_portrait} {
        width: auto;
      }

      .button-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .action-dropdown-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .yb-accent-color-icon,
        .yb-scalable-icon-container {
          display: none;
          svg {
            width: 22px;
            height: 22px;
          }

          @media #{$screen_tablets_portrait} {
            display: block;
          }

          @media #{$screen_ipad_portrait} {
            display: block;
          }
        }

        .action-dropdown-button-text {
          display: block;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;

          @media #{$screen_tablets_portrait} {
            display: none;
          }

          @media #{$screen_ipad_portrait} {
            display: none;
          }
        }
      }
    }

    .yb-button {
      .button-content {
        justify-content: space-between;
      }
    }

    .hidden {
      visibility: hidden;
    }

    .go-to-region {
      margin: 0 $space-sm;
    }
  }
}

.review-action-dropdown {
  .dropdown-item-dot {
    width: $space-sm;
    height: $space-sm;
    border-radius: 50%;
    background: $yembo-blue;
    margin-right: $space-md - $space-xs;
    flex-shrink: 0;
  }

  .review-action-dropdown-item-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .menu-item {
    &:not(.reviewed) {
      .dropdown-item-text {
        font-weight: bold;
      }
    }

    &.analyzed {
      .dropdown-item-dot {
        visibility: visible;
      }
    }

    .dropdown-item-dot {
      visibility: hidden;
    }
  }
}
