.impersonation {
  flex: 1;

  .input-wrapper {
    // Styles below are used to align text inputs and drop downs horizontally.
    .impersonation-multi-select .dropdown-content {
      min-height: 24px;

      .dropdown-text {
        align-items: flex-end;
      }
    }

    .impersonation-role {
      margin-top: $space-sm;

      .dropdown-content {
        margin-top: 5px;
      }
    }
  }
}
