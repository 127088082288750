.tool-info {
  position: absolute;
  z-index: $popup-z-index;
  background: $gray-background;
  border-radius: $border-radius-md;
  width: 212px;
  &.dark {
    background: $dm-background-light;
  }
  &.left {
    left: 55px;
    &.regular::before {
      content: '';
      width: 0px;
      height: 0px;
      border: 0.8em solid transparent;
      position: absolute;
      left: -20px;
      top: 5%;
      border-right: 10px solid $info-light;
    }
    &.dark::before {
      content: '';
      width: 0px;
      height: 0px;
      border: 0.8em solid transparent;
      position: absolute;
      left: -20px;
      top: 5%;
      border-right: 10px solid $dm-background-light;
    }
  }
  &.top {
    top: 50px;
    right: 10px;
    &.regular::before {
      content: '';
      width: 0px;
      height: 0px;
      border: 0.8em solid transparent;
      position: absolute;
      left: 71.5%;
      top: -20px;
      border-bottom: 10px solid lightblue;
    }
    &.dark::before {
      content: '';
      width: 0px;
      height: 0px;
      border: 0.8em solid transparent;
      position: absolute;
      left: 71.5%;
      top: -20px;
      border-bottom: 10px solid $dm-background-light;
    }
  }
  .info-graphic {
    height: 144px;
    border-top-left-radius: $border-radius-md;
    border-top-right-radius: $border-radius-md;
  }
  .info-content {
    color: $body-color;
    padding: $space-md;
    &.dark {
      color: $white;
    }
  }
}
