.claim-type {
  .claim-category-dropdown {
    margin-bottom: $space-lg;
  }

  .yb-radio-button {
    height: 24px;
  }

  .text-field {
    &.claim-subtype {
      margin-left: $space-xl !important;
    }

    &.disabled {
      opacity: 0.25;
      .textfield-input-wrapper {
        border-color: $gray-border;
      }
    }

    &:hover {
      .textfield-input-wrapper {
        border-color: $gray-dark;
      }
    }
  }

  .text-field,
  .claim-category-dropdown {
    max-width: 224px;
    margin-left: $space-xl;
  }
}

.claim-category-menu {
  max-width: 218px;
  left: $space-xl + $space-xs;
  top: $space-sm;
}
