@import './AnnotationListing';
@import './ScanOverview';
@import './DatasetOverview';
@import './_components/ScanCard';

.annotate {
  display: flex;
  width: 100%;
  overflow-x: scroll;

  &.two-columns {
    .content-container {
      background-color: transparent;
      padding: 0;
      box-shadow: 10px 4px 20px rgb(0 0 0 / 10%);
    }
  }

  .yb-header {
    padding-bottom: $space-xs * 3;
  }

  .content-container {
    padding: 0;
  }

  .navbar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 58px;

    .yb-top-navbar {
      color: $white;
      height: 52px;
    }

    .annotate-listing-search {
      display: block;
      min-width: 264px;
      margin-bottom: $space-sm * 3;

      .search-bar-input-wrap {
        background-color: rgba(#fff, 0.19); /* stylelint-disable-line declaration-property-value-allowed-list */

        .search-bar-input {
          color: rgba(#fff, 0.6); /* stylelint-disable-line declaration-property-value-allowed-list */
        }
      }
    }
  }

  .yb-mover-screen {
    .content-wrap {
      max-height: calc(100vh - 16px); // Considering .annotate container horizontal scroll
    }
  }

  .empty-listing-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .empty-state-text {
      color: $muted-text-color;
      text-align: center;
    }
  }

  .fetching-new-data {
    position: absolute;
    bottom: 0;
    background: $white;
    width: 100%;
    text-align: center;
    padding-top: $space-md;
  }

  .scrollable-div {
    overflow-y: scroll;
    padding: 0 $space-sm 0 $space-md;
    margin-right: $space-xs;

    @include scrollbar-light-theme;
  }
}
