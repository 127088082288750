.count-badge {
  background-color: $yembo-blue;
  height: 16px;
  border-radius: $border-radius-md;
  padding: 0 $space-sm;
  display: flex;
  justify-content: center;
  align-items: center;

  // text-properties
  color: $white;
  line-height: 16px;
  font-weight: 500;
  @extend .tiny-text;
}
