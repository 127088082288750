@import '../ReviewCarousel';
@import '../ReviewActionBar';
@import '../ReviewToggler';
@import '../ProcessingScreen';

.specialty-item-review {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: $space-sm;

  .review-action-bar {
    margin-right: $space-md;

    .left-actions-wrapper,
    .right-actions-wrapper {
      .yb-tool-tip {
        display: flex;
        flex-grow: 1;
        max-width: 64px;
        min-width: 50px;
      }
    }
  }

  .specialty-item-review-contents {
    overflow-y: scroll;
    scroll-behavior: smooth;
    margin-right: $space-xs;
    padding-right: $space-xs;
  }

  .map-container {
    min-width: 296px;
  }
}
