.labels-list {
  flex: 1 1;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  gap: $space-md;
  margin: $space-md;
}

.add-label-popover {
  top: 52px;
  left: 8px;
}
