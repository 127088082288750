@import './DatasetInfo';
@import './AnnotationTasksList';
@import './LabelsList';
@import './_components/AddTaskPopover';
@import './_components/LabelPopover';

.dataset-overview {
  display: flex;
  align-items: stretch;
  height: 100%;

  .col-one,
  .col-two {
    background-color: $white;
    border-radius: $border-radius-lg;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .col-one {
    width: 320px;
    min-width: 320px;
    margin-right: $space-sm;
    box-shadow: 10px 4px 20px rgb(0 0 0 / 10%);
    padding: $space-md 0;

    .overview-header {
      padding: 0 $space-md;
    }

    @media (min-width: 1920px) {
      width: 402px;
      min-width: 402px;
    }

    @media (max-width: 1365px) {
      width: 302px;
      min-width: 302px;
    }
  }

  .col-two {
    flex: 1 1;
    box-shadow: 0 10px 20px rgb(0 0 0 / 10%);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: $space-md;

    .overview-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $space-md $space-md 0 $space-md;

      .right-container {
        display: flex;
        gap: $space-sm;
      }
    }

    .scans-list-wrapper {
      flex: 1 1;
    }

    .add-button-container {
      display: flex;
      justify-content: flex-end;
      padding: $space-xs $space-md 0 $space-md;

      .yb-button {
        padding: 0 $space-md;
        margin-top: $space-sm;
      }
    }
  }
}

.remove-scan-modal {
  .modal-subheader-secondary-text {
    margin-top: $space-sm;
  }
}
