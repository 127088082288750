.underwriting-types-list {
  display: flex;
  flex-direction: column;
  gap: $space-sm;
  padding: 0 $space-sm;
  max-height: 236px;
  margin-bottom: $space-sm;
  overflow-y: scroll;

  .yb-radio-button {
    margin-right: $space-md;
    height: fit-content;
  }
}
