@import './PhoneContent/index';

.phone-wrapper {
  border-radius: $border-radius;
  margin-left: $space-md;
  margin-right: $space-md;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  @media #{$screen-tablets-portrait} {
    display: none;
  }
  @media #{$screen_mobile_all_orientations} {
    display: none;
  }

  .invite-mms {
    width: 176px;
    margin-top: $space-sm;
  }

  .phone-svg {
    display: flex;
    position: absolute;
    align-items: center;
    align-self: center;
    height: 480px;
    width: 240px;
    filter: drop-shadow($z-depth-md);
  }
}
