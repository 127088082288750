.kepler-chip {
  display: flex;
  align-items: center;
  gap: $space-md;
  padding: $space-xs $space-md;
  background-color: #f0f7ff; /* stylelint-disable-line declaration-property-value-allowed-list */
  width: fit-content;
  border-radius: 100px;
  height: fit-content;
  transition: $ui-transition background-color;

  .chip-text {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .chip-close-icon {
    cursor: pointer;
    border-radius: 2px;
    &:hover {
      background-color: $menu-item-selected;
    }
  }

  &:hover {
    background-color: $menu-item-hover;
  }

  &.selected {
    background-color: $menu-item-selected;
  }
}
