.animated-line-wrap {
  display: flex;
  position: relative;

  @media #{$screen-tablets-portrait} {
    transform: rotate(-90deg) translateY(55px);
  }

  .progress-bar-fill {
    width: 5px;
    height: 115px;

    @media #{$screen-tablets-portrait} {
      width: 5px;
      height: 118px;
    }

    &.not-active-line {
      transition: $ui-transition all 0.5s;
      background-color: $gray-background;
    }
    &.active-line {
      transition: $ui-transition all 0.5s;
      background-color: $yembo-blue;
    }
  }
  .line-svg {
    stroke: $gray-border;
    stroke-width: 5px;
  }
}
