.awaiting-image-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  animation: flickerAnimation 2s infinite;

  @keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }

  .placeholder-illustration {
    height: 100%;
  }
}
