.review-toggler {
  border-radius: 16px;
  border: 1px solid $gray-border;
  padding-top: $space-md;
  margin-top: $space-md;

  .toggle-chips-container {
    display: flex;
    padding-left: $space-md;
    padding-bottom: $space-sm;

    .toggle-chips {
      margin-right: $space-sm;
      width: 122px;
      height: 32px;

      &.is-disabled {
        opacity: 0.35;
        pointer-events: none;
      }

      .chip-label {
        width: 100%;

        .chip-content-wrapper {
          display: flex;
          justify-content: space-between;
          width: 100%;
          font-size: 14px;
        }
      }
    }
  }
  .tags-grid {
    padding: $space-sm $space-md $space-md;
    .grid-head-row,
    .grid-row {
      display: grid;
      grid-template-columns: 35% 10% 50% 5%;
      align-items: center;
    }
    .grid-head-cell,
    .grid-cell {
      padding: $space-sm $space-md;
      &.right-aligned {
        margin-left: auto;
      }
    }

    .grid-head-row {
      font-weight: 600;
    }
    .grid-row {
      cursor: pointer;

      .grid-cell {
        &.textfield {
          .textfield-input-wrapper {
            height: 34px;
          }
        }
        .remove-icon {
          visibility: hidden;
        }
      }

      &:nth-of-type(even) {
        background-color: $gray-background-light;

        &:hover {
          .textfield-input-wrapper {
            background-color: $white;
            border-color: transparent;
          }
        }
        &:active,
        &:focus,
        &:focus-within {
          .textfield-input-wrapper {
            background-color: $white;
            border-color: $yembo-blue;
          }
        }
      }
      &:nth-of-type(odd) {
        &:hover {
          .textfield-input-wrapper {
            background-color: $gray-background-light;
            border-color: transparent;
          }
        }
        &:active,
        &:focus,
        &:focus-within {
          .textfield-input-wrapper {
            background-color: $gray-background-light;
            border-color: $yembo-blue;
          }
        }
      }
      &:hover {
        .remove-icon {
          visibility: visible;

          &:hover {
            svg path {
              stroke: $danger-base;
            }
          }
        }
      }
    }

    &.empty {
      margin: $space-sm;
    }
  }
}
