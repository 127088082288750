@import './EmailTempateEditor/';

.messaging {
  height: 100%;
  display: flex;
  flex-direction: column;
  &-content {
    height: calc(100% - 57px);
    display: flex;
    align-items: stretch;
    margin: $space-md $space-sm 0 $space-md;
  }
  &-left {
    flex: 2;
    border-radius: $border-radius-lg;
    border: 1px solid $gray-border;
    margin-right: $space-sm;
    padding: $space-md 0 $space-md $space-md;
    &-content {
      box-sizing: border-box;
      height: 100%;
      margin-right: $space-xs;
      padding: 0 $space-md 0 0;

      .section-heading {
        display: flex;
        align-items: center;
        font-weight: 600;
        margin-bottom: $space-xs;
        .heading-icon {
          margin-right: $space-sm;
        }
      }
      .section-content {
        padding: $space-sm 0;
        .template-tab {
          border-radius: $border-radius-sm;
          display: flex;
          align-items: center;
          padding: $space-xs $space-xs $space-xs $space-sm;
          margin-bottom: $space-sm;
          cursor: pointer;
          transition: box-shadow 0.2s ease-in-out;
          &:hover {
            /* stylelint-disable-next-line declaration-property-value-allowed-list */
            background-color: lighten($yembo-blue, 40%);
            color: $yembo-blue;
            .caret-right {
              margin-left: auto;
              svg * {
                stroke: $yembo-blue;
              }
            }
          }
          &.active {
            /* stylelint-disable-next-line declaration-property-value-allowed-list */
            color: darken($yembo-blue, 10%);
            font-weight: 600;
            .caret-right {
              svg * {
                stroke: darken($yembo-blue, 10%);
              }
            }
          }
          .tab-icon {
            margin-right: $space-sm;
          }
          .caret-right {
            margin-left: auto;
            svg * {
              stroke: $gray-dark;
            }
          }
        }
      }
    }
  }
  &-right {
    flex: 5;
    display: grid;
    grid-template-rows: 1fr auto;
    padding: 0 0 $space-md 0;
    box-sizing: border-box;
    &-content {
      padding: 0 $space-sm $space-sm $space-sm;

      .messaging-textarea {
        margin-top: $space-xs;
      }
      .email-fields-wrap {
        margin: $space-sm $space-sm $space-sm 0;

        .yb-text-field {
          margin-top: -$space-sm;
          padding-bottom: $space-sm;
        }
      }
      .expanded-email {
        width: 80%;
        margin-bottom: $space-xl + $space-sm;
      }
      textarea.yb-text-area {
        width: 80%;
        height: 20%;
      }
      .messaging-bottom-text {
        margin-top: $space-xl + $space-xs;
        margin-bottom: $space-md;
      }
      .yb-text-area {
        margin-left: 0;
        width: calc(100% - $space-md);
      }
      .variables-list {
        display: grid;
        grid-template-columns: auto 1fr;
        .variable {
          font-weight: 600;
          word-wrap: unset;
          display: list-item;
          list-style-type: disc;
          list-style-position: inside;
          padding: $space-xs $space-xs $space-sm $space-sm;
          margin-right: $space-md;
          &::marker {
            font-size: 0.7em;
          }
        }
        .description {
          margin-inline-start: 0;
          padding: $space-xs;
        }
      }
    }
    &-footer {
      display: flex;
      justify-content: flex-end;
      padding: $space-sm * 3 $space-sm $space-sm $space-sm;
    }
  }
  .yb-dropdown {
    min-width: 180px;
    width: max-content;
    position: absolute;
    top: $space-sm;
    right: $space-xl;
    z-index: $window-z-index + 2;
    .company-dropdown {
      .dropdown-content {
        display: flex;
        align-items: center;
        .dropdown-text.selected {
          color: $top-nav-text-color !important; /* stylelint-disable-line declaration-property-value-allowed-list */
          display: flex;
          align-items: center;
          margin-right: $space-md;
          transition: $ui-transition color;
          &:hover {
            color: $white !important;
            .yb-icon svg * {
              stroke: $white;
            }
          }
          .yb-icon {
            margin-right: $space-sm;
            svg * {
              stroke: $top-nav-text-color;
              transition: $ui-transition stroke;
            }
          }
        }
      }
    }
  }
}

.scrollable {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.company-dropdown-menu {
  padding: $space-sm 0;
  .menu-item {
    white-space: nowrap !important;
    padding: $space-sm $space-md;
  }
}
