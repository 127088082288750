.hazards-list {
  flex: 1;
  .hazards-list-header {
    font-style: normal;
    font-weight: 700;
    font-size: 17.5px;
    line-height: 24px;
    margin: $space-sm 0 $space-xs 0;
    padding: $space-md 0;
  }
  .hazards-list-content {
    border: 1px solid #dce0ef;
    border-radius: $border-radius-sm;
    .grid-head-row {
      display: grid;
      grid-template-columns: auto 39% 72px;
      background: $white;
      color: $header-color;
      font-weight: 600;
      height: 48px;
      align-items: center;
      min-width: 653px;
      div {
        margin: 0 $space-md;
      }
    }
    .grid-hazard-row {
      display: grid;
      grid-template-columns: auto 39% 72px;
      background-color: $white;
      align-items: center;
      min-height: 48px;
      .project-grid-cell {
        align-items: center;
        margin: 0 $space-md;

        .eye-icon {
          opacity: 0;
          transition: $ui-transition opacity;
          padding: $space-sm;
          border-radius: $border-radius-sm;
        }
      }

      .invisible {
        opacity: 0;
        padding: $space-sm;
      }

      &:nth-of-type(even) {
        background-color: $gray-background-light;
        .eye-icon {
          transition: $ui-transition background-color;
          &:hover {
            background-color: $menu-item-hover;
          }
        }
      }

      &:hover {
        .project-grid-cell:last-child {
          .eye-icon {
            opacity: 1;
            cursor: pointer;
            &:hover {
              svg * {
                stroke: $yembo-blue;
              }
            }
          }
        }
      }
    }
  }
}
