@import './RequirementList';

.reset-password {
  min-width: 370px;

  .passwords-match {
    color: $success-base;
    line-height: 16px;
    font-size: 12px;

    &.hide {
      visibility: hidden;
    }
  }

  .message {
    border-radius: $border-radius-md;
    padding: $space-sm;
    margin-bottom: $space-sm;
    background-color: #fff4d6; /* stylelint-disable-line declaration-property-value-allowed-list */

    &.error {
      background-color: $danger-xlight;
    }
  }
  .requirements-wrapper {
    margin-bottom: $space-xl + $space-sm;
  }

  .yb-tertiary-button {
    margin-top: $space-sm;
  }
}
