.yb-search-bar.insured-listing-search {
  $transparent-white: rgba(#fff, 0.19);

  min-width: 264px;

  .search-bar-input-wrap {
    background-color: $transparent-white; /* stylelint-disable-line declaration-property-value-allowed-list */

    .search-bar-input {
      color: rgba(#fff, 0.6); /* stylelint-disable-line declaration-property-value-allowed-list */
    }

    .filters-icon {
      border-radius: $border-radius-sm;
      transition: $ui-transition background-color;
      cursor: pointer;

      svg * {
        stroke: rgba(#fff, 0.85); /* stylelint-disable-line declaration-property-value-allowed-list */
      }

      &:hover {
        background-color: $transparent-white; /* stylelint-disable-line declaration-property-value-allowed-list */
      }

      &.active {
        background-color: $transparent-white; /* stylelint-disable-line declaration-property-value-allowed-list */

        svg * {
          stroke: $white;
        }
      }
    }
  }
}
