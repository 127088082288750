/*
    These global styles are imported before the component styles in _import.scss
    so they can be overridden in the component stylesheets
*/

.popper {
  z-index: 1;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

input {
  border: none;
  outline: none;
  &:focus,
  &:hover {
    border: none;
    outline: none;
  }
}

svg {
  &.pallet-icon,
  &.worker-icon {
    stroke: none !important;
  }
}

button {
  background-color: $white; // Overrides the gray background older browsers add by default (i.e. Chrome 76)
}

.no-pointer-events {
  pointer-events: none;
}

body {
  margin: 0;
  height: 100%;
}

html {
  height: 100%;
  font-size: 14px;
}

#root {
  min-height: 100%;
  width: 100%;
  min-width: 100%;
  font-weight: 300;
  display: flex;
  transition: opacity 0.5s ease-in-out;

  #page {
    flex: 1;
  }
  .app {
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;

    > :first-child {
      flex: 1;
    }
  }
}

// Global styles, style overrides, mixins and variables

$yembo-blue-dark-mode: #579cfe;
$ui-transition: 0.2s ease-in-out;

@mixin scrollbar-light-theme {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $gray-border;
  }
}

.scrollbar-dark-theme {
  @include scrollbar-light-theme;
  &::-webkit-scrollbar-thumb {
    background: $white-muted;
  }
}

.scrollbar-light-theme {
  @include scrollbar-light-theme;
}

.yb-popover-menu .menu-element {
  @include scrollbar-light-theme;
}

.alert {
  z-index: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: $space-xl;
}

@mixin content-fade-in {
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}

.content-fade-in {
  @include content-fade-in;
}

.update-checker-modal {
  .modal-subheader-secondary-text {
    margin-top: $space-sm;
  }

  .button-main-text {
    display: flex;
    gap: $space-xs;

    .timer {
      min-width: 32px;
    }
  }
}
