@import './components/EmployeeDetails';
@import './components/ConfirmationDialog';

.employees {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .tab-body {
    flex-grow: 1;
    width: 100%;
    overflow: hidden;

    .tab-body-columns {
      display: flex;
      height: 100%;
      flex-shrink: 0;
      flex-basis: auto;

      .empty-list {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .grid-table-wrapper {
      $grid-template-columns: 56px auto 26% 15% 12% 13% 56px 56px;

      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: scroll hidden;
      max-width: calc(100% - 20px);
      margin: 0 $space-xs 0 $space-md;
      transition: flex 0.2s linear;
      flex-basis: 100%;
      flex-shrink: 0;

      &.collapse {
        flex-basis: calc(100% - 306px);
        flex-shrink: 1;
      }

      .avatar {
        img {
          height: $space-lg;
          border-radius: 50%;
        }
      }
      .phone-cell {
        white-space: nowrap;
      }

      .grid-head-row {
        display: grid;
        grid-template-columns: $grid-template-columns;
        background: $white;
        color: $header-color;
        font-weight: 600;
        height: 48px;
        align-items: center;
        margin: $space-xs $space-xs * 3 0 0;
        min-width: 900px;
      }

      .grid-table-body {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        overflow-x: hidden;
        min-width: 900px;

        .grid-row {
          display: grid;
          flex: 1 1;
          grid-template-columns: $grid-template-columns;
          background: $white;
          box-sizing: border-box;
          border: 1px solid $gray-border;
          border-radius: $border-radius-md;
          align-items: center;
          margin: $space-xs $space-sm $space-sm 0;
          padding: $space-xs * 3 0;
          cursor: pointer;
          transition: $ui-transition box-shadow;

          &:hover {
            box-shadow: $z-depth-md;

            .delete-cell,
            .reset-cell {
              visibility: visible;
              overflow: visible;
            }
          }
        }

        > .scrollbar-light-theme {
          min-width: 900px;
        }
      }

      .grid-cell {
        margin: 0 $space-md;
        display: flex;
        align-items: center;
        height: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.right-aligned {
          justify-content: flex-end;

          .cell-column {
            align-items: flex-end;
            text-align: end;
          }
        }

        .cell-column {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }

        .yb-tool-tip {
          .tooltip-content {
            top: 80% !important;
          }
        }
      }

      .delete-button {
        display: flex;
        width: 100%;
        min-width: 24px;
        min-height: 22px;
        justify-content: flex-end;
        position: relative;
        .yb-icon {
          position: absolute;
        }
      }
      .reset-button {
        display: flex;
        width: 100%;
        min-width: 24px;
        min-height: 22px;
        justify-content: flex-end;
        position: relative;
        padding-right: 0;
        .yb-icon {
          position: absolute;
        }
      }

      .delete-cell,
      .reset-cell {
        visibility: hidden;

        .delete-button {
          position: relative;
          &:hover {
            svg * {
              stroke: $danger-base !important;
            }
          }
        }
      }
    }
  }

  .add-button-container {
    display: flex;
    justify-content: flex-end;
    padding: 0 $space-md;
    max-height: 80px;
    transition: max-height 0.2s;

    .yb-button {
      padding: 0 $space-md;
      margin-bottom: $space-sm;
      margin-top: $space-lg;
    }

    &.hidden {
      max-height: 0;
    }
  }
}
