.camera-controls {
  position: absolute;
  bottom: 100px;
  left: 70px;
  z-index: $window-z-index;

  @include content-fade-in;

  &.hide {
    display: none;
  }
  .camera-pan-hud {
    width: 100px;
    height: 100px;
    cursor: pointer;
    opacity: 0.5;
    transition: 0.3s ease-in-out opacity;
    &:hover {
      opacity: 1;
    }
    .circle {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      position: absolute;
      background: $dm-background-mid;
      transition: 0.4s opacity;
      transform: rotate(45deg);
      border: 1px solid $dm-background-extra-light;
      .arrow {
        transform: rotate(-45deg);
        position: absolute;
      }
    }

    .pan_right {
      -webkit-clip-path: polygon(50% 0, 50% 50%, 100% 50%, 100% 0);
      clip-path: polygon(50% 0, 50% 50%, 100% 50%, 100% 0);
      transition: 0.2s ease-in background;
      .arrow {
        top: 17px;
        left: 68px;
      }
      &:hover {
        .arrow {
          path {
            stroke: $yembo-blue-dark-mode;
          }
        }
      }
      &.active {
        background: $yembo-blue;
        .arrow {
          path {
            stroke: white;
          }
        }
      }
    }

    .pan_down {
      -webkit-clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%);
      clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%);
      .arrow {
        top: 68px;
        left: 64px;
      }

      &:hover {
        .arrow {
          path {
            stroke: $yembo-blue-dark-mode;
          }
        }
      }
      &.active {
        background: $yembo-blue;
        .arrow {
          path {
            stroke: white;
          }
        }
      }
    }

    .pan_left {
      -webkit-clip-path: polygon(0 100%, 50% 100%, 50% 50%, 0% 50%);
      clip-path: polygon(0 100%, 50% 100%, 50% 50%, 0% 50%);
      .arrow {
        top: 65px;
        left: 23px;
      }
      &:hover {
        .arrow {
          path {
            stroke: $yembo-blue-dark-mode;
          }
        }
      }
      &.active {
        background: $yembo-blue;
        .arrow {
          path {
            stroke: white;
          }
        }
      }
    }

    .pan_up {
      -webkit-clip-path: polygon(50% 50%, 0 50%, 0 0, 50% 0);
      clip-path: polygon(50% 50%, 0 50%, 0 0, 50% 0);
      &:hover {
        .arrow {
          path {
            stroke: $yembo-blue-dark-mode;
          }
        }
      }
      .arrow {
        top: 22px;
        left: 18px;
      }
      &.active {
        background: $yembo-blue;
        .arrow {
          path {
            stroke: white;
          }
        }
      }
    }

    .horizontal-down {
      width: 100px;
      height: 1px;
      border-bottom: 1px solid $dm-background-extra-light;
      transform: rotate(45deg);
      position: absolute;
      bottom: 48px;
      left: 1px;
    }

    .horizontal-up {
      width: 100px;
      height: 1px;
      border-bottom: 1px solid $dm-background-extra-light;
      transform: rotate(-45deg);
      position: absolute;
      bottom: 49px;
      right: -1px;
    }
  }

  .camera-zoom-hud {
    height: 30px;
    width: 90px;
    margin-top: $space-lg;
    position: absolute;
    left: 5px;
    border: 1px solid $dm-background-extra-light;
    border-radius: $button-border-radius;
    background: $dm-background-mid;
    opacity: 0.5;
    transition: 0.3s ease-in-out opacity;
    display: flex;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    .zoom-button {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      &.zoom_out {
        border-top-left-radius: $button-border-radius;
        border-bottom-left-radius: $button-border-radius;
      }

      &.zoom_in {
        border-top-right-radius: $button-border-radius;
        border-bottom-right-radius: $button-border-radius;
      }
      &.active {
        background: $yembo-blue;
        svg * {
          stroke: white;
        }
      }

      &:hover:not(.active) {
        svg * {
          stroke: $yembo-blue-dark-mode;
        }
      }
    }
    .zoom-divider {
      height: 100%;
      width: 1px;
      background: $dm-background-extra-light;
    }
  }
}

@keyframes test {
  0% {
    left: 70px !important;
  }
  25% {
    left: 90px !important;
  }
  50% {
    left: 120px !important;
  }
  100% {
    left: 180px !important;
  }
}
