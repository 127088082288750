.animated-icons-content {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  width: 210px;
  height: 464px;
  animation: popIn 0.5s 1;

  @keyframes popIn {
    from {
      opacity: 0;
      transform: scale3d(0.5, 0.5, 0.5);
    }
    to {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }

  @media #{$screen-tablets-portrait} {
    display: none;
  }

  @media #{$screen_mobile_all_orientations} {
    display: none;
  }

  .animated-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    .animated-robot,
    .animated-phone,
    .animated-interior {
      display: flex;
      width: 80%;
    }
  }
}
.static-room-content {
  animation: popIn 0.5s 1;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 210px;
  height: 464px;
}
.message-content {
  animation: popIn 0.5s 1;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 210px;
  height: 464px;
  position: relative;

  .date-wrapper {
    animation: popIn 0.5s 1;
    position: absolute;
    z-index: 2;
    top: 35px;
  }
  .image-wrapper {
    position: absolute;
    top: 60px;
  }
  .company-logo {
    position: absolute;
    top: 75px;
    max-height: $space-lg;
    max-width: 80px;
  }
  .message-text,
  .link-text {
    position: absolute;
    margin-left: $space-sm;
    width: 180px;
    animation: popIn 0.5s 1;
  }
  .message-text {
    margin-top: -$space-lg;
  }
  .link-text {
    margin-top: $space-xxl;
  }
}
