.floating-section-list {
  position: absolute;
  z-index: $floating-window-z-index;

  .header {
    text-transform: capitalize;
  }

  .body {
    display: flex;
    flex-direction: column;
  }
}

.scrollable-div {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-right: $space-sm;
  height: 100%;

  .inbox-header-text {
    z-index: 10;
  }
}

.item-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $space-xs $space-sm;
  border-radius: $border-radius-sm;
  transition: $ui-transition background-color;
  cursor: pointer;

  p {
    white-space: nowrap;
  }

  :first-child {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .item-quantity {
    flex-shrink: 0;
    visibility: hidden;
    width: 70px;

    &.show {
      visibility: visible;
    }
  }

  &:hover {
    background-color: $menu-item-hover;

    :last-child {
      visibility: visible;
    }
  }
}

.modal-search-bar {
  &.yb-search-bar {
    margin-bottom: $space-xs;
    right: 0;
    .search-bar-input-wrap {
      height: $space-xl;
    }

    .search-bar-input {
      flex-grow: 1;
    }
  }
}
