.welcome-modal {
  height: 100%;
  width: 100%;
  position: absolute;

  .modal-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    background: $modal-overlay;
    z-index: 100;
    justify-content: center;
    align-items: center;
    display: flex;

    .modal-container {
      width: 604px;
      height: 596px;
      background-color: $white;
      border-radius: $border-radius-lg;
      box-shadow: $modal-overlay-box-shadow;
      display: flex;
      flex-direction: column;
      opacity: 0;
      @include content-fade-in;

      .modal-hero {
        justify-content: center;
        align-items: center;
        display: flex;
        flex: 1;
        color: $body-color;
      }
      .modal-cta {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 256px;
        background-color: $gray-background-light;
        border-radius: $border-radius-lg;
        color: $body-color;
        padding: 0 $space-xxl;
        .cta-text {
          margin-bottom: $space-sm;
          text-align: center;
        }
        .cta-btn-container {
          margin-top: $space-lg;
        }
      }
    }
  }
}
