.posed-image-viewer {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10;
  .fuzz {
    height: 100%;
    width: 100%;
    z-index: 10;
    position: fixed;
    backdrop-filter: blur(7px);
    cursor: zoom-out;
  }
  .frustum-hd-photo {
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 12;
    height: calc(100% - 40px);
    left: calc(50% + 20px);
    transform: translateX(-50%);
  }
  .downloader-container {
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: $dm-background-mid;
    height: 32px;
    width: 32px;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 20;
    cursor: pointer;
    &:hover {
      background-color: $dm-background-light;
      svg {
        path {
          stroke: $dm-yembo-blue;
        }
      }
    }
    .downloading-spinner {
      position: relative;
      left: 4px;
    }
  }
}
