.dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .badge-container {
    display: flex;

    .yb-chip {
      margin-right: $space-sm;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .dashboard-subheader {
    margin: $space-md;

    .yb-dropdown {
      min-width: 180px;
      width: max-content;
      .company-dropdown {
        .dropdown-text.selected {
          display: flex;
          align-items: center;
          margin-right: $space-md;
          .yb-icon {
            margin-right: $space-sm;
            svg * {
              stroke: $yembo-blue;
            }
          }
        }
      }
    }
  }
}
