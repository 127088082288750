@import './Copyright';
@import './SectionHeader';
@import './LoginForm';
@import './VerifyMfaForm';
@import './ResendMfa';
@import './VerifyBackupCode/';
@import './ResetPassword';
@import './AccountActivation';

#login-new {
  margin: 0;
  padding: 0;
  width: 100%;
  background: $yembo-navy;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  .container {
    width: 936px;
    min-height: 544px;
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: $muted-container-box-shadow;
    display: flex;
    position: relative;

    #yembo-logo {
      position: absolute;
      top: 32px;
      left: 40px;
    }

    .login-form,
    .verify-mfa-form,
    .verify-backup-code,
    .resend-mfa,
    .forgot-password,
    .reset-password {
      margin-top: calc($space-sm * 8);
      margin-bottom: calc($space-sm * 8);
      display: flex;
      flex-direction: column;
      width: 328px;
    }

    .illustration-container {
      display: flex;
      width: 504px;
      justify-content: center;
      align-items: center;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      box-shadow: 0 0 0 30px $white inset !important;
      -webkit-box-shadow: 0 0 0 30px $white inset !important;
    }

    .forgot-password-button,
    .resend-otc-button {
      padding: 0;
      text-align: left;

      .button-content {
        display: block;
      }
    }

    .verify-backup-code,
    .forgot-password {
      .yb-text-field {
        margin-top: 56px;
        margin-bottom: 96px;
      }
    }

    @media #{$screen-tablets-portrait} {
      width: 540px;
      height: 660px;
      flex-direction: column;
      padding-left: 104px;
      box-sizing: border-box;

      #yembo-logo {
        position: static;
        margin-top: 80px;
      }

      .login-form,
      .verify-mfa-form,
      .verify-backup-code,
      .resend-mfa,
      .forgot-password,
      .reset-password {
        margin-top: $space-lg !important;
      }

      .illustration {
        display: none;
      }
    }

    @media #{$screen_mobile_all_orientations} {
      width: 80%;
      flex-direction: column;

      #yembo-logo {
        position: static;
        padding: $space-md;
        width: 100%;
      }

      > div {
        margin: auto;
        margin-top: 0 !important;
        padding: 0 $space-md;
        max-width: 90%;
      }

      .illustration {
        display: none;
      }
    }
  }

  .copyright-new {
    color: $mobile-gray-background;
  }
}
