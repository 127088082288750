@import './TopSlider';

.top-bar {
  flex: 0 0 $toolbar-size;
  display: flex;
  background: $dm-background-mid;
  z-index: $z-index-menu;
  -webkit-box-shadow: inset 0px 0px 0px 1px $dm-background-light;
  -moz-box-shadow: inset 0px 0px 0px 1px $dm-background-light;
  box-shadow: inset 0px 0px 0px 1px $dm-background-light;
  .toolbar-icon-container {
    flex: 0 0 $toolbar-size;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title-bar {
    flex: 0 0 $toolbar-size;
    display: flex;
    align-items: center;
    background: $dm-background-mid;

    .title {
      align-self: flex-end;
      background: $dm-background-mid;
      font-weight: 600;
      color: $white;
      border-radius: $toolbar-radius $toolbar-radius 0 0;
    }

    .tools {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .main-menu-button {
    cursor: pointer;
    transition: background-color 0.2s ease-in;

    &:hover {
      .yb-icon {
        svg * {
          stroke: $yembo-blue-dark-mode;
        }
      }
    }

    &.active {
      background-color: $yembo-blue;
      &:hover {
        background-color: $yembo-blue-dark;

        .yb-icon {
          svg * {
            stroke: $white;
          }
        }
      }
    }
  }

  .top-slider-wrapper {
    position: fixed;
    left: 64px;
    z-index: 1;
  }

  .screen-title {
    margin: auto;
    padding-left: 160px;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    @include content-fade-in;

    .model-navigation-item {
      height: 100%;
      width: 24px;
      margin: 0px $space-lg;
      cursor: pointer;

      &:hover {
        svg * {
          stroke: $yembo-blue-dark-mode;
        }
      }
      .navigation-caret {
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: row;
      }
    }
  }
  .utility-btns {
    display: flex;
    flex-direction: row;
    width: 200px;
    justify-content: flex-end;
    .loading-status {
      padding-top: 10px;
      display: flex;
      flex-direction: row;
      margin-right: 10px;
      &.move-out {
        -webkit-animation-name: fadeOut;
        -webkit-animation-duration: 0.6s;
        animation-name: fadeOut;
        animation-duration: 0.6s;
        animation-fill-mode: forwards;
      }
      > span {
        margin-right: 10px;
      }
      .top-spin {
        margin-top: 1px;
      }
    }
  }

  .main-menu-wrapper {
    align-self: flex-end;
    > * {
      transform: translate(0.5em, 0.25em);
      width: 210px;
    }
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0.9;
  }

  40% {
    opacity: 0.5;
  }

  60% {
    opacity: 0.3;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    display: none;
  }
}
