.card-wrapper {
  height: 260px;

  .scan-card {
    height: 100%;
    width: 100%;
    min-width: 300px;
    cursor: pointer;

    .scan-details {
      display: grid;
      grid-template-columns: auto auto;
      column-gap: $space-sm;
      row-gap: $space-xs;
      margin: $space-lg;
    }

    .video-preview-image {
      background-color: #191b1e; /* stylelint-disable-line declaration-property-value-allowed-list */
    }
  }
}
