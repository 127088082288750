#login-new {
  .login-form {
    .fields-container {
      display: flex;
      flex-direction: column;
      &.locked {
        > * {
          pointer-events: none;
          opacity: 30%;
        }
      }
      > * {
        padding: 0;
        margin-bottom: $space-md;
      }
    }

    position: relative;

    .yb-primary-button.default {
      margin-top: $space-lg;
    }

    .yb-text-field .textfield-error {
      display: none;
    }

    .forgot-password-button {
      width: 128px;
    }
  }
}
