.filter-panel {
  border-radius: $border-radius-lg;
  min-width: 296px;
  padding: 0 $space-md $space-md $space-md;
  margin-left: $space-sm;
  overflow: hidden;
  background-color: $white;
  box-shadow: 0 10px 20px rgb(0 0 0 / 10%);

  .filter-panel-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    opacity: 0;
    animation: 0.7s ease-in 0.2s forwards fadeIn;

    .filter-panel-header {
      height: 56px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $gray-border;

      .filter-panel-title {
        align-self: center;
        font-size: 17.5px;
      }

      .yb-icon {
        align-self: flex-start;
        transform: translate(50%, 50%);
        cursor: pointer;

        svg {
          width: 16px;
          height: 16px;
          * {
            transition: $ui-transition stroke;
          }
        }

        &:hover {
          svg * {
            stroke: $yembo-blue;
          }
        }
      }
    }

    .filter-panel-content {
      flex: 1 1;
      overflow: hidden scroll;
      padding: 0 $space-sm 0 0;
      margin-right: -$space-xs * 3;

      .collapsible-filter-section {
        display: flex;
        flex-direction: column;
        margin-top: $space-md;

        &.open {
          .collapsible-header {
            .caret-icon {
              svg * {
                stroke: $yembo-blue;
              }
            }
          }
        }
        .collapsible-header {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          border-radius: $border-radius-sm;
          transition: $ui-transition background-color;
          padding: $space-xxs $space-sm;
          font-weight: 600;

          .collapsible-title {
            flex: 1;
          }

          .caret-icon {
            margin-right: $space-sm;

            svg * {
              stroke: $gray-dark;
            }
          }

          &:hover {
            background-color: #f0f7ff; /* stylelint-disable-line declaration-property-value-allowed-list */
            cursor: pointer;

            .caret-icon {
              svg * {
                stroke: $yembo-blue;
              }
            }
          }
        }

        .collapsible-content {
          margin: $space-sm 0 0 $space-xl;
          max-width: 184px;

          .yb-radio-button {
            height: auto;
            margin-bottom: $space-sm;

            &:last-child {
              margin-bottom: 0px;
            }
          }

          .yb-datepicker {
            margin-bottom: $space-sm;
          }

          .yb-checkbox {
            margin-bottom: $space-sm;
            text-transform: capitalize;

            &:last-child {
              margin-bottom: 0;
            }

            &.checkbox-enabled-text {
              opacity: 1;

              .checkbox-contents {
                .visible-checkbox {
                  opacity: 0.5;
                }
              }
            }
          }

          @keyframes activity-collapse {
            from {
              max-height: 0;
              overflow: hidden;
            }
            to {
              max-height: 800px;
              overflow: hidden;
            }
          }

          animation: $ui-transition activity-collapse;
        }
      }
    }

    .clear-filters-btn {
      width: fit-content;
      padding: $space-xxs * 3 $space-xs !important;
      margin: $space-sm * 3 0 0 $space-xs !important;
      align-self: end;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
