@import './TutorialTooltip';
@import './BasicTooltip';
@import './ToolInfo/';

.tool-bar-item {
  display: flex;
  align-items: stretch;
  height: $toolbar-size;
  width: $toolbar-size;
  transition: $ui-transition background;
  cursor: pointer;

  span.scissors {
    path {
      fill: transparent;
    }
  }

  &.disabled {
    cursor: not-allowed;
  }
  > .wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &.active {
      &:hover {
        .yb-icon {
          svg * {
            stroke: $yembo-blue-dark-mode;
          }
        }
      }
    }

    &.disabled {
      .yb-icon {
        opacity: 0.35;
      }
    }

    &.selected {
      background: $yembo-blue;
      &:hover {
        background-color: $yembo-blue-dark;
        > .yb-icon {
          svg * {
            stroke: $white;
          }
        }
      }
    }
    .yb-icon {
      svg * {
        transition: $ui-transition all;
      }
    }
  }
}
